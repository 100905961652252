import { Select, VStack, Text } from '@chakra-ui/react';
import React from 'react';

const Dropdown = ({ element, value, handleChange }) => {
  const { id, label, disabled, options, optional, invisible } = element;

  return (
    <VStack
      width={'100%'}
      alignItems={'flex-start'}
      display={invisible ? 'none' : 'flex'}
      position={'relative'}
    >
      <Text m="0" alignSelf="flex-start" color="white">
        {label}
      </Text>
      <Select
        w={'100%'}
        maxW={'30rem'}
        disabled={disabled}
        required={!optional}
        value={value}
        onChange={handleChange}
        name={id}
        color={'white'}
        focusBorderColor="brand.400"
        placeholder="Please select an option"
        position={'relative'}
        style={{
          border: 0,
          background: '#414141',
          borderRadius: '12px',
          gap: '12px',
          margin: '0'
        }}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.text}
          </option>
        ))}
      </Select>
    </VStack>
  );
};

export default Dropdown;
