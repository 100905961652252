import {
  Grid,
  Input,
  Spinner,
  VStack,
  Text,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const FieldUploadElement = ({
  element,
  handleChange,
  value,
  formData,
  showTitle = true,
  fieldConstraints,
  updateFieldConstraints
}) => {
  const {
    id,
    title,
    subTitle,
    warnings,
    action,
    invisible,
    required,
    uploadDisabled,
    fileId,
    fileSize,
    fileTypeList,
    widgetRules
  } = element;

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!formData || !widgetRules?.length) return;
    updateFieldConstraints(id, widgetRules[0]);
  }, [formData]);

  const uploadFile = useCallback(
    (file, e) => {
      const apiAction = action?.apiAction;

      if (!apiAction) return;

      setIsLoading(true);

      api.workflowCta
        .ctaRequest(
          apiAction.endpoint,
          apiAction.methodType,
          {
            file: file,
            fileId: fileId
          },
          'multipart/form-data'
        )
        .then((res) => res.data)
        .then((data) => {
          handleChange({
            target: {
              name: e.target.name,
              value: data.urlPath
            }
          });
          dispatch(
            setAlert({
              title: 'Uploaded file successfully!',
              status: 'success'
            })
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            setAlert({
              title: err.message || 'Failed to upload file!',
              status: 'error'
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [action, fileId, handleChange, dispatch]
  );

  const handleLocalChange = useCallback(
    (e) => {
      let file = e.target.files[0];
      if (file) {
        if (
          fileTypeList?.length &&
          fileTypeList.every((type) => !new RegExp(type).test(file.type))
        ) {
          return dispatch(
            setAlert({
              title: 'Please provide a valid file format',
              status: 'error'
            })
          );
        }
        setFile(e.target.files[0]);
        uploadFile(file, e);
      }
    },
    [fileTypeList, uploadFile, dispatch]
  );

  return (
    <VStack
      w={'100%'}
      alignItems={'flex-start'}
      display={
        fieldConstraints && fieldConstraints['invisible'] ? 'none' : 'grid'
      }
      gap={1}
    >
      {showTitle && title && <Text color={'white'}>{title}</Text>}
      {showTitle && subTitle && (
        <Text fontSize={'0.75rem'} color={'shadedGray.400'}>
          {subTitle}
        </Text>
      )}

      <Grid gridTemplateColumns={'1fr auto'} gap={2}>
        <Input
          type={'file'}
          color={'white'}
          focusBorderColor="brand.400"
          onChange={handleLocalChange}
          name={id}
          accept={fileTypeList?.join(', ') || ['*/*']}
          disable={uploadDisabled || isLoading ? 'on' : 'off'}
          style={{
            border: 0,
            background: '#414141',
            borderRadius: '12px',
            gap: '12px',
            margin: '0'
          }}
          required={required}
          max={fileSize}
        />
        <VStack
          justifyContent={'center'}
          alignItems={'flex-end'}
          w={{ base: '3rem', md: '6rem' }}
        >
          {isLoading ? (
            <Spinner color={'brand.200'} size={'sm'} />
          ) : value?.length ? (
            <AiOutlineCheckCircle color={'#81EBAB'} size={'1.5rem'} />
          ) : null}
        </VStack>
      </Grid>
      {warnings?.length && (
        <UnorderedList pt={1}>
          {warnings.map((warning, i) => (
            <ListItem fontSize={'0.75rem'} color={'shadedGray.400'} key={i}>
              {warning}
            </ListItem>
          ))}
        </UnorderedList>
      )}
    </VStack>
  );
};

export default FieldUploadElement;
