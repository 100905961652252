import api from '../../api';
import { INVESTMENTS, INVESTMENTS as types } from '../constants';

export const getInvestmentKycStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_INVESTMENT_KYC_STATUS
    });
    const { data } = await api.investments.getInvestmentKycStatus();

    return dispatch({
      type: INVESTMENTS.GET_INVESTMENT_KYC_STATUS_SUCCESS,
      payload: {
        status: data
      }
    });
  } catch (error) {
    return dispatch({
      type: types.GET_INVESTMENT_KYC_STATUS_FAILURE,
      payload: error
    });
  }
};
