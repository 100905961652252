import api from '../../api';
import auth from '../../auth';
import { AUTH as types } from '../constants';

export const updateLoginIniateData = (data) => ({
  type: types.UPDATE_LOGIN_INITIATE_DATA,
  payload: data
});

export const updateSubmitOtpData = (data) => ({
  type: types.UPDATE_SUBMIT_OTP_DATA,
  payload: data
});

export const getMe = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_ME
    });

    const { data } = await api.auth.me();

    dispatch({
      type: types.GET_ME_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: types.GET_ME_FAILURE,
      payload: error
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await auth.removeToken();
    return dispatch({
      type: types.LOGOUT
    });
  } catch (error) {
    return dispatch({
      type: types.LOGOUT_FAILURE
    });
  }
};
