import { mapScreenIdToNumber } from '../../utils';
import { WORKFLOW as types } from '../constants';
import { updateKycWorkflow } from './utils';

const initialState = {
  workflowId: null,
  isLoading: false,
  isError: false,
  error: null,
  data: null,
  currentLocalScreen: -1,
  formData: {}
};

const workflowReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_WORKFLOW:
      return {
        ...state,
        workflowId: payload.workflowId,
        isLoading: true,
        isError: false,
        error: null,
        data: null,
        currentLocalScreen: -1
      };

    case types.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflowId: payload.workflowId,
        isLoading: false,
        isError: false,
        error: null,
        data: {
          currentStep: payload.data.currentStep,
          totalSteps: payload.data.totalSteps,
          completedStep: payload.data.completedStep,
          screens: payload.data.screens.reduce(
            (prev, screen, i) => ({ ...prev, [i]: screen }),
            {}
          )
        },
        currentLocalScreen: payload.data.currentStep
      };
    case types.GET_WORKFLOW_FAILURE:
      return {
        ...state,
        workflowId: payload.workflowId,
        isLoading: false,
        isError: true,
        error: payload.data,
        data: null,
        currentLocalScreen: -1
      };

    case types.WORKFLOW_CTA_SUCCESS:
      const { updateAction, screenFormData, nextScreen, currentScreenNo } =
        payload.data;

      let updatedScreens = updateKycWorkflow({
        screens: state.data.screens,
        updateAction: updateAction
      });

      const screenIdToNumberMap = mapScreenIdToNumber(updatedScreens);

      const nextScreenId = nextScreen?.screenId;
      const screenAlreadyExists = !!screenIdToNumberMap[nextScreenId];
      if (screenAlreadyExists) {
        updatedScreens = {
          ...updatedScreens,
          [screenIdToNumberMap[nextScreenId]]: nextScreen
        };
      } else {
        screenIdToNumberMap[nextScreenId] = currentScreenNo + 1;
        updatedScreens = {
          ...updatedScreens,
          [currentScreenNo + 1]: nextScreen
        };
      }

      return {
        ...state,
        workflowId: payload.workflowId,
        isLoading: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          totalSteps: payload.data.totalSteps || state.data.totalSteps,
          currentStep: screenAlreadyExists
            ? state.data.currentStep
            : state.data.currentStep + 1,
          screens: {
            ...state.data.screens,
            ...updatedScreens
          }
        },
        currentLocalScreen: nextScreenId
          ? parseInt(screenIdToNumberMap[nextScreenId])
          : -1,
        formData: {
          ...state.formData,
          [currentScreenNo]: screenFormData
        }
      };

    case types.UPDATE_CURRENT_WORKFLOW_SCREEN:
      return {
        ...state,
        workflowId: payload.workflowId,
        currentLocalScreen: payload.currentScreen
      };

    case types.CLEAR_WORKFLOW:
      return {
        ...state,
        workflowId: null,
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        formData: {}
      };

    default:
      return { ...state };
  }
};

export default workflowReducer;
