import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Logo from '../../components/Logo';
import {
  Stack,
  VStack,
  Text,
  Grid,
  Image,
  Heading,
  HStack,
  Button
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import Sidebar from './Siderbar';
import FullPageSpinner from '../../components/FullPageSpinner';
import { logout } from '../../store/actions/authActions';
import WorkflowCard from '../../components/WorkflowCard';
import { getInvestmentKycStatus } from '../../store/actions/investmentActions';
import api from '../../api';
import {
  INVESTMENT_KYC_STATUS,
  WORKFLOWS,
  WORKFLOW_STATUS
} from '../../constants';
import EmailVerifyCard from '../../components/EmailVerifyCard';
import { BsArrowRight } from 'react-icons/bs';
import { setAlert } from '../../store/actions/alertActions';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [isAutoLoginLoading, setIsAutoLoginLoading] = useState(false);
  const { data: user, isLoading } = useSelector((state) => state.auth.user);
  const investmentKycStatus = useSelector(
    (state) => state.investment.investmentKyc
  );

  useEffect(() => {
    dispatch(getInvestmentKycStatus());
  }, []);

  useEffect(() => {
    if (!isEligibleToInvest) return;

    triggerAutoLogin({ showAlert: false });
  }, [investmentKycStatus]);

  const triggerAutoLogin = useCallback(
    ({ showAlert = false }) => {
      setIsAutoLoginLoading(true);
      api.investments
        .autoLogin()
        .then((res) => res.data)
        .then((data) => {
          window.location.href = data.redirectUrl;
        })
        .catch((err) => {
          let errorMessage;
          console.log(err);

          if (err.data) {
            try {
              const errorData = JSON.parse(err.data);
              errorMessage = errorData.message;
            } catch (error) {
              console.log('Error parsing JSON:', error);
            }
          }

          if (showAlert) {
            dispatch(
              setAlert({
                title:
                  errorMessage ||
                  err.message ||
                  'Something went wrong! Please try again later',
                status: 'error'
              })
            );
          }
        })
        .finally(() => setIsAutoLoginLoading(false));
    },
    [dispatch]
  );

  const isEligibleToInvest = useMemo(
    () =>
      investmentKycStatus?.status?.kycStatus ===
        INVESTMENT_KYC_STATUS.COMPLETED && user?.emailVerified,
    [user, investmentKycStatus]
  );

  const workflows = useMemo(
    () =>
      !investmentKycStatus.status
        ? []
        : Object.keys(WORKFLOWS).map((workflowId) => {
            let status = WORKFLOW_STATUS.NEW;
            switch (workflowId) {
              case 'INVESTMENT_KYC_WORKFLOW':
                status = investmentKycStatus?.status.kycWorkflowStatus;
                break;
              case 'INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW':
                status = investmentKycStatus?.status.addNriBankWorkflowStatus;
                break;
              default:
                status = WORKFLOW_STATUS.NEW;
            }
            const workflow = {
              ...WORKFLOWS[workflowId],
              status
            };

            return workflow;
          }),
    [investmentKycStatus]
  );

  const isEmailVerified = user?.emailVerified;

  const areAllWorkflowsPending = useMemo(
    () =>
      workflows.length &&
      workflows.every(
        (workflow) => workflow.status === WORKFLOW_STATUS.PROCESSING
      ),
    [workflows]
  );

  if (isLoading || investmentKycStatus.isLoading) return <FullPageSpinner />;

  return (
    <>
      <DashboardLayout>
        <VStack
          width={'100%'}
          py={{ base: '8px', lg: '10px' }}
          px={5}
          w={{ base: '100%' }}
          overflowY={{ lg: 'scroll' }}
          h={{ lg: '100vh' }}
          className="scroll"
          minH={'75vh'}
          alignItems={'flex-start'}
        >
          <Stack alignSelf="start" px={{ sm: 4, lg: 8 }}>
            <Logo />
          </Stack>
          <VStack
            alignItems={{ base: 'center', sm: 'flex-start' }}
            py={{ base: 0, sm: 0 }}
            px={{ base: 0, sm: 10 }}
            h={'100%'}
            justifyContent={'center'}
            gap={6}
          >
            <Image
              src={
                'https://d17i1y54ugg6uo.cloudfront.net/Product-files/Investments+product/1x/coin-stack-grow.png'
              }
              alt={''}
            />
            <Heading
              color={'white'}
              lineHeight={'140%'}
              letterSpacing={'0.25px'}
              textAlign={{ base: 'center', sm: 'start' }}
            >
              {' '}
              Hi{' '}
              {user.firstName.charAt(0).toUpperCase() +
                user.firstName?.slice(1)}
              ! Invest in India, <br /> seamlessly.{' '}
            </Heading>

            <EmailVerifyCard
              email={user.email}
              isEmailVerified={isEmailVerified}
            />

            <Grid
              gridTemplateColumns={{ base: '1fr' }}
              gap={{ base: 8, sm: 5 }}
              justifyContent={'flex-start'}
              maxW={'100%'}
            >
              {workflows.map((workflow) => (
                <WorkflowCard
                  workflow={workflow}
                  key={workflow.id}
                  status={workflow.status}
                  isEmailVerified={isEmailVerified}
                />
              ))}
            </Grid>
            {areAllWorkflowsPending && (
              <Text color={'#ABACB6'} maxW={'25rem'} fontSize={'sm'}>
                You can start investing once the verification is completed.
                We’ll notify you on the same.
              </Text>
            )}

            {isEligibleToInvest && (
              <Button
                onClick={() => triggerAutoLogin({ showAlert: true })}
                bg={'brand.500'}
                rounded={'lg'}
                cursor={'pointer'}
                fontWeight={600}
                _hover={{
                  textDecoration: 'underline'
                }}
                rightIcon={<BsArrowRight display={'inline'} size={'1.2rem'} />}
                isLoading={isAutoLoginLoading}
              >
                Start Investing
              </Button>
            )}
          </VStack>
        </VStack>
        <Sidebar user={user} onLogout={() => dispatch(logout())} />
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
