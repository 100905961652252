import api from '../../api';
import { CONFIG as types } from '../constants';

export const getAllowedCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_ALLOWED_COUNTRIES
    });

    const { data } = await api.config.allowedCountries();

    dispatch({
      type: types.GET_ALLOWED_COUNTRIES_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.GET_ALLOWED_COUNTRIES_FAILURE,
      payload: error
    });
  }
};
