import { Box } from '@chakra-ui/react';

export default function AuthLayout({ children, ...props }) {
  return (
    <Box
      bg="black"
      w="100%"
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box bg="altGray.300" rounded="xl" maxW={'500px'} w={'100%'} mx={2}>
        <Box m={{ base: '1rem', sm: '2.5rem' }}>{children}</Box>
      </Box>
    </Box>
  );
}
