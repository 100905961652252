import {
  Button,
  Stack,
  StackDivider,
  VStack,
  Text,
  Flex
} from '@chakra-ui/react';
import React from 'react';
import { MdArrowForward } from 'react-icons/md';

const Sidebar = ({ user, onLogout }) => {
  return (
    <VStack
      divider={<StackDivider borderColor="whiteAlpha.300" />}
      maxHeight={{ base: 'none', lg: '100vh' }}
      pb={{ base: 2, lg: 0 }}
    >
      <Stack h={{ base: 'auto', lg: '60vh' }} w="100%">
        <Flex
          paddingTop={{ base: 2, lg: '10vh' }}
          pl={{ base: 8, lg: 5 }}
          pb={{ base: 4, lg: 0 }}
          w="100%"
        ></Flex>
      </Stack>

      <VStack alignSelf="start" px="8">
        <VStack paddingEnd={20}>
          <Text alignSelf="start" fontSize="md" color="gray">
            Signed in:
          </Text>
          <Text
            fontSize="2xl"
            alignSelf="flex-start"
            color="white"
            wordBreak={'break-word'}
          >
            {user.name}
          </Text>
          <Button
            variant="outline"
            type="logout"
            color="gray"
            rounded="lg"
            onClick={onLogout}
            fontWeight={600}
            alignSelf="start"
            px={5}
            rightIcon={<MdArrowForward size="1.5rem" />}
          >
            Logout
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Sidebar;
