import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetch } from './api';
import Dashboard from './views/Dashboard';
import { logout } from './store/actions/authActions';
import Workflow from './views/Workflow';

const AxiosInterceptor = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetch.interceptors.response.use(
      (res) => res,
      (err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        return Promise.reject(err);
      }
    );
  }, [dispatch]);

  return <></>;
};

function AuthenticatedApp() {
  return (
    <BrowserRouter>
      {/* {import.meta.env.PROD && <SegmentAnalytics />} */}
      <AxiosInterceptor />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/workflows/:workflowPath" element={<Workflow />} />
        {/* Add routes above */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export { AuthenticatedApp };
