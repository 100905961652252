import auth from '../../auth';

export default function authApi(fetch, baseUrl = '') {
  return {
    me() {
      return fetch.get(`${baseUrl}/user/me`);
    },

    loginInitiate({ code, number, deviceId }) {
      return fetch.post(`${baseUrl}/login/initiate`, {
        code,
        number,
        deviceId
      });
    },

    submitOtp(formData) {
      return fetch.post(`${baseUrl}/login/submit-otp`, formData);
    },

    onboard(formData) {
      return fetch.post(`${baseUrl}/login/onboard`, formData);
    },

    login(formData) {
      return fetch.post(`${baseUrl}/login`, formData);
    },

    logout() {
      let promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          await auth.removeToken();
          resolve('User logged out!');
          // reject('An error occurred!');
        }, 2000);
      });
      return promise;
    },

    resendEmail(email) {
      return fetch.put(`${baseUrl}/user/email`, {
        email
      });
    }
  };
}
