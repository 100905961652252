import { Box, Grid } from '@chakra-ui/react';

export default function DashboardLayout({ children, ...props }) {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', lg: '1fr auto' }}
      bg="black"
      minHeight={'100vh'}
      h={{ lg: '100vh' }}
      alignItems="center"
      m={0}
      // divider={
      //   <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      // }
    >
      {children}
    </Grid>
  );
}
