import {
  VStack,
  Text,
  List,
  ListItem,
  ListIcon,
  HStack
} from '@chakra-ui/react';
import React from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

const WarningsElement = ({ element }) => {
  const { warnings, invisible } = element;

  return (
    <VStack
      gap={1}
      alignItems={'center'}
      w={'100%'}
      textAlign={'center'}
      display={invisible ? 'none' : 'flex'}
    >
      <List w={'100%'}>
        {warnings.map((warning, i) => (
          <ListItem key={i} color={'yellow.400'} w={'100%'}>
            <HStack w={'100%'} justifyContent={'center'}>
              <RiErrorWarningLine />
              <Text fontSize={'0.8rem'}>{warning}</Text>
            </HStack>
          </ListItem>
        ))}
      </List>
    </VStack>
  );
};

export default WarningsElement;
