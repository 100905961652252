import { Image, VStack, Text } from '@chakra-ui/react';
import React from 'react';
import { getSpacing } from '../utils';

const LottieFileElement = ({ element }) => {
  const {
    margin,
    alignment,
    height,
    invisible,
    loopMode,
    lottieFileUrl,
    placeholderImgUrl,
    reverse
  } = element;

  //todo: add Lottie animation
  return (
    <VStack
      alignItems={'center'}
      w={'100%'}
      textAlign={'center'}
      mt={getSpacing(margin?.top) || 0}
      mb={getSpacing(margin?.bottom) || 0}
      ml={getSpacing(margin?.left) || 0}
      mr={getSpacing(margin?.right) || 0}
    >
      <Image
        src={placeholderImgUrl?.replace('{{size}}', '3x')}
        alt={''}
        height={height}
        width={'auto'}
      />
    </VStack>
  );
};

export default LottieFileElement;
