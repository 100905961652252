import React, { useCallback, useEffect, useState } from 'react';
import ElementBuilder from './ElementBuilder';
import {
  ELEMENT_TYPES,
  getFormConstraints,
  getInitialFormData,
  getSpacing,
  getValidationData
} from './utils';
import { Button, FormControl, Grid, VStack } from '@chakra-ui/react';
import CTAElement from './factoryComponents/CTAElement';
import api from '../api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/actions/alertActions';
import { workFlowCtaSuccess } from '../store/actions/workflowActions';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import {
  ELEMENT_VALIDATOR_TYPES,
  WORKFLOWS,
  WORKFLOW_ACTION_TYPES
} from '../constants';
import { useNavigate } from 'react-router-dom';
import { evaluatePredicate } from '../utils/predicateEvaluator';
import { doesExist } from '../utils';

const ScreenBuilder = ({
  workflowId,
  workflowName,
  screen,
  onBackClick,
  onNextClick,
  currenScreenNo,
  onWorkflowFinish,
  initFormData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formValidators, setFormValidators] = useState(
    getValidationData(screen)
  );

  const [formData, setFormData] = useState(
    getInitialFormData(screen, initFormData)
  );

  const [formConstraints, setFormConstraints] = useState(
    getFormConstraints(screen, formData)
  );

  const updateFieldConstraints = useCallback(
    (elementId, widgetRule) => {
      const predicateResult = evaluatePredicate(widgetRule.predicate, formData);

      setFormConstraints((prev) => ({
        ...prev,
        [elementId]: {
          ...prev[elementId],
          ...widgetRule.result[predicateResult]
        }
      }));
    },
    [formData]
  );

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  useEffect(() => {
    setFormConstraints(getFormConstraints(screen, initFormData));
    setFormData(getInitialFormData(screen, initFormData));
    setFormValidators(getValidationData(screen));
  }, [screen]);

  const validateForm = useCallback(
    (formData) => {
      for (const field of Object.keys(formData)) {
        //static validators
        if (formValidators[field].validators?.length) {
          for (const validator of formValidators[field].validators) {
            switch (validator.type) {
              case ELEMENT_VALIDATOR_TYPES.STRING_VALIDATOR:
                // if field is blank
                if (validator.blank === true && formData[field] === '') {
                  dispatch(
                    setAlert({
                      title: validator.message,
                      status: 'error'
                    })
                  );
                  return false;
                }
                break;
              case ELEMENT_VALIDATOR_TYPES.REGEX_VALIDATOR:
                if (!new RegExp(validator.regex).test(formData[field])) {
                  dispatch(
                    setAlert({
                      title: validator.message,
                      status: 'error'
                    })
                  );
                  return false;
                }
                break;
              case ELEMENT_VALIDATOR_TYPES.RANGE_VALIDATOR:
                //todo: detect date in input using regex, add date conversion only for date inputs
                const inputDate = new Date(formData[field]);
                const minDate = validator.min ? new Date(validator.min) : null;
                const maxDate = validator.max ? new Date(validator.max) : null;
                if (validator.inclusive) {
                  if (minDate && inputDate < minDate) {
                    dispatch(
                      setAlert({
                        title: validator.message,
                        status: 'error'
                      })
                    );
                    return false;
                  }
                  if (maxDate && inputDate > maxDate) {
                    dispatch(
                      setAlert({
                        title: validator.message,
                        status: 'error'
                      })
                    );
                    return false;
                  }
                } else {
                  if (minDate && inputDate <= minDate) {
                    dispatch(
                      setAlert({
                        title: validator.message,
                        status: 'error'
                      })
                    );
                    return false;
                  }
                  if (maxDate && inputDate >= maxDate) {
                    dispatch(
                      setAlert({
                        title: validator.message,
                        status: 'error'
                      })
                    );
                    return false;
                  }
                }
                break;
              default:
                console.log('unhandled validator: ', validator.type);
            }
          }
        }

        //dynamic validators
        if (
          formValidators[field].isRequired &&
          !formConstraints[field]?.invisible &&
          !formData[field]
        ) {
          dispatch(
            setAlert({
              title: `${field
                .replace('uploaded_docs_', '')
                .split('_')
                .join(' ')} field can not be empty`,
              status: 'error'
            })
          );
          return false;
        }
      }

      return true;
    },
    [formConstraints, formValidators, dispatch]
  );

  const startNewWorkflow = useCallback(
    (workflowId) => {
      const workflowPath = WORKFLOWS[workflowId]?.path;
      if (!workflowPath)
        return console.log('missing path for workflow ID: ', workflowId);

      navigate(`/workflows/${workflowPath}`);
    },
    [navigate]
  );

  const submitWorkflowScreen = useCallback(
    (endpoint, methodType, formData) => {
      setIsLoading(true);

      api.workflowCta
        .ctaRequest(endpoint, methodType, formData)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.currentStep === data.totalSteps) {
            dispatch(
              setAlert({
                title: `Your ${workflowName} is under review! Hang tight`,
                status: 'success'
              })
            );
          } else if (data.nextScreen === null) {
            onWorkflowFinish();
          }
          dispatch(
            workFlowCtaSuccess({
              workflowId,
              updateAction: data.action,
              screenFormData: formData,
              nextScreen: data.nextScreen,
              currentScreenNo: currenScreenNo,
              totalSteps: data.totalSteps
            })
          );
        })
        .catch((err) => {
          let errorMessage;
          console.log(err);

          if (err.data) {
            try {
              const errorData = JSON.parse(err.data);
              errorMessage = errorData.message;
            } catch (error) {
              console.log('Error parsing JSON:', error);
            }
          }

          dispatch(
            setAlert({
              title:
                errorMessage ||
                err.message ||
                'Something went wrong! Please try again later',
              status: 'error'
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [currenScreenNo, workflowId, workflowName, onWorkflowFinish, dispatch]
  );

  const handleCtaClick = useCallback(
    ({ action }) => {
      const apiAction = action?.apiAction;
      if (!validateForm(formData)) return;

      switch (action?.actionType) {
        case WORKFLOW_ACTION_TYPES.START_WORKFLOW_ACTION:
          startNewWorkflow(action.workflowId);
          break;
        default:
          submitWorkflowScreen(
            apiAction.endpoint,
            apiAction.methodType,
            formData
          );
      }
    },
    [formData, startNewWorkflow, submitWorkflowScreen, validateForm]
  );

  return (
    <FormControl w={'100%'} display={'flex'} justifyContent={'center'}>
      <Grid
        gridTemplateRows={'1fr auto auto'}
        w={'100%'}
        minH={{ base: '65vh', lg: '70vh' }}
        maxW={'30rem'}
      >
        <VStack
          justifyContent={
            screen?.contents?.childrenAlignment?.toLowerCase() || 'flex-start'
          }
          w={'100%'}
          gap={
            doesExist(screen?.contents?.childrenSpacing)
              ? getSpacing(screen?.contents?.childrenSpacing)
              : 6
          }
        >
          <Grid
            gridTemplateColumns={onBackClick && onNextClick ? '1fr 1fr' : '1fr'}
            w={'100%'}
            display={onBackClick || onNextClick ? 'grid' : 'none'}
            mt={4}
          >
            {onBackClick && (
              <Button
                color="white"
                variant={'link'}
                onClick={onBackClick}
                justifySelf={'flex-start'}
                leftIcon={
                  <MdArrowLeft
                    cursor={'pointer'}
                    style={{ alignSelf: 'flex-start' }}
                    color="white"
                    size={'1.5rem'}
                  />
                }
              >
                Back
              </Button>
            )}

            {onNextClick && (
              <Button
                color="white"
                variant={'link'}
                onClick={onNextClick}
                justifySelf={'flex-end'}
                rightIcon={
                  <MdArrowRight
                    cursor={'pointer'}
                    style={{ alignSelf: 'flex-start' }}
                    color="white  "
                    size={'1.5rem'}
                  />
                }
              >
                Next
              </Button>
            )}
          </Grid>
          {screen?.screenType === 'VideoUploadScreen'
            ? screen?.contents?.videoBlock && (
                <ElementBuilder
                  element={screen.contents.videoBlock}
                  // key={element.id}
                  value={formData[screen.contents.videoBlock.id]}
                  handleChange={handleChange}
                  formData={formData}
                  fieldConstraints={
                    formConstraints[screen.contents.videoBlock.id]
                  }
                  updateFieldConstraints={updateFieldConstraints}
                />
              )
            : screen?.contents?.children?.map((element) => (
                <ElementBuilder
                  element={element}
                  key={element.id}
                  value={formData[element.id]}
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                  fieldConstraints={formConstraints[element.id]}
                  updateFieldConstraints={updateFieldConstraints}
                />
              ))}
        </VStack>

        {/* FOOTER ELEMENTS */}
        {screen?.contents?.footerElements?.length > 0 && (
          <VStack
            gap={getSpacing(screen?.contents?.footerSpacing) || 5}
            mt={6}
            justifySelf={'flex-end'}
            justifyContent={
              screen?.contents?.childrenAlignment?.toLowerCase() || 'flex-start'
            }
            w={'100%'}
          >
            {screen?.contents?.footerElements.map((element) =>
              element.elementType === ELEMENT_TYPES.CTA_ELEMENT.type ? (
                <CTAElement
                  key={element.id}
                  element={element}
                  onClick={handleCtaClick}
                  isLoading={isLoading}
                />
              ) : (
                <ElementBuilder
                  element={element}
                  key={element.id}
                  value={formData[element.id]}
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                  fieldConstraints={formConstraints[element.id]}
                  updateFieldConstraints={updateFieldConstraints}
                />
              )
            )}
          </VStack>
        )}

        {/* CTA ELEMENTS */}
        {screen?.contents?.ctaElements?.length && (
          <VStack gap={6} mt={8} justifySelf={'flex-end'} w={'100%'}>
            {screen?.contents?.ctaElements.map((element) => (
              <CTAElement
                key={element.id}
                element={element}
                onClick={handleCtaClick}
                isLoading={isLoading}
              />
            ))}
          </VStack>
        )}
      </Grid>
    </FormControl>
  );
};

export default ScreenBuilder;
