import React, { useEffect, useRef, useState } from 'react';
import { Button, HStack, VStack, Text, Spinner, Box } from '@chakra-ui/react';
import Webcam from 'react-webcam';
import RecordRTC from 'recordrtc';
import {
  BsPlayCircleFill,
  BsFillStopFill,
  BsUpload,
  BsEyeFill
} from 'react-icons/bs';
import { MdRestartAlt } from 'react-icons/md';

const WebcamRecorder = ({
  permissionGranted,
  requestPermission,
  videoMessage,
  stream,
  handleUpload,
  isUploaded,
  isUploading
}) => {
  const webcamRef = useRef(null);
  const recordRTCRef = useRef(null);
  const recordButtonRef = useRef(null);
  const uploadButtonRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedData, setRecordedData] = useState(null);
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [isWebCamLoaded, setIsWebCamLoaded] = useState(false);

  const startWebcamRecording = (stream) => {
    const videoStream = stream.getVideoTracks()[0];
    const audioStream = stream.getAudioTracks()[0];
    const combinedStream = new MediaStream([videoStream, audioStream]);

    const recordRTC = RecordRTC(combinedStream, {
      type: 'video'
    });

    recordRTC.startRecording();
    recordRTCRef.current = recordRTC;
    setRecording(true);
  };

  useEffect(() => {
    return () => {
      if (webcamRef.current && webcamRef.current.stream) {
        const videoTrack = webcamRef.current.stream.getVideoTracks()[0];
        videoTrack.stop();
      }
    };
  }, []);

  const stopRecording = () => {
    recordRTCRef.current.stopRecording(() => {
      const blob = recordRTCRef.current.getBlob();
      setRecordedData(blob);
    });
    setRecording(false);
  };

  const handleStartRecording = () => {
    setPreviewEnabled(false);
    if (!permissionGranted) {
      // Request permission if it hasn't been granted yet
      requestPermission();
    } else {
      // Start webcam recording if permission is already granted
      startWebcamRecording(webcamRef.current.stream);
    }
  };

  const onRetakeRecording = () => {
    setPreviewEnabled(false);
    setRecordedData(false);
    setRecording(false);
  };

  useEffect(() => {
    if (recordButtonRef.current) {
      const recordButtonElement = recordButtonRef.current;
      if (recordButtonElement) {
        recordButtonElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [recordButtonRef.current]);

  useEffect(() => {
    if (uploadButtonRef.current) {
      const uploadButtonElement = uploadButtonRef.current;
      if (uploadButtonElement) {
        uploadButtonElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [uploadButtonRef.current]);

  return (
    <VStack width={'100%'} alignItems={'flex-start'} mt={4}>
      <VStack width={'100%'} align="center" rounded={'md'} overflow={'hidden'}>
        {previewEnabled && (
          <VStack
            width={'100%'}
            align="center"
            rounded={'md'}
            overflow={'hidden'}
          >
            <video controls src={URL.createObjectURL(recordedData)} />
          </VStack>
        )}
        <VStack
          width={'100%'}
          align="center"
          display={previewEnabled || !permissionGranted ? 'none' : 'flex'}
        >
          {videoMessage && (
            <Text color={'shadedGray.400'} textAlign={'center'}>
              Keep your face in the frame and say the words written below:
            </Text>
          )}
          <VStack
            width={'100%'}
            align="center"
            rounded={'md'}
            overflow={'hidden'}
          >
            {' '}
            <Webcam
              audio={true}
              ref={webcamRef}
              muted
              onLoadedData={() => setIsWebCamLoaded(true)}
            />
            {!isWebCamLoaded && (
              <VStack w={'100%'}>
                <Box minH={'20rem'}>
                  <Spinner color={'brand.400'} />
                </Box>
              </VStack>
            )}
          </VStack>
          {videoMessage && (
            <Text color={'white'} textAlign={'center'}>
              {videoMessage}
            </Text>
          )}
        </VStack>

        {previewEnabled ? (
          <HStack gap={8}>
            {' '}
            <Button
              onClick={onRetakeRecording}
              mt={4}
              variant={'outline'}
              color={'white'}
              _hover={{
                backgroundColor: 'white',
                color: 'black'
              }}
              rounded={'full'}
              size={'sm'}
              fontWeight={'light'}
              rightIcon={<MdRestartAlt />}
            >
              Retake
            </Button>
            <Button
              onClick={() => handleUpload(recordedData)}
              mt={4}
              color={'black'}
              bg={'white'}
              _hover={{
                backgroundColor: 'brand.200',
                color: 'black'
              }}
              rounded={'full'}
              size={'sm'}
              fontWeight={'light'}
              rightIcon={<BsUpload />}
              isLoading={isUploading}
              ref={uploadButtonRef}
            >
              Upload
            </Button>
          </HStack>
        ) : recording ? (
          <HStack>
            <Button
              onClick={stopRecording}
              mt={4}
              color={'black'}
              bg={'white'}
              _hover={{
                backgroundColor: 'brand.200',
                color: 'black'
              }}
              rounded={'full'}
              size={'sm'}
              fontWeight={'light'}
              rightIcon={<BsFillStopFill />}
            >
              Stop Recording
            </Button>
          </HStack>
        ) : recordedData ? (
          <HStack>
            <Button
              mt={4}
              color={'black'}
              bg={'white'}
              _hover={{
                backgroundColor: 'brand.200',
                color: 'black'
              }}
              onClick={() => setPreviewEnabled(true)}
              rounded={'full'}
              size={'sm'}
              fontWeight={'light'}
              rightIcon={<BsEyeFill />}
            >
              Preview
            </Button>{' '}
          </HStack>
        ) : (
          <HStack>
            <Button
              onClick={handleStartRecording}
              mt={4}
              color={'black'}
              bg={'white'}
              _hover={{
                backgroundColor: 'brand.200',
                color: 'black'
              }}
              rounded={'full'}
              size={'sm'}
              fontWeight={'light'}
              rightIcon={<BsPlayCircleFill />}
              ref={recordButtonRef}
            >
              Start Recording
            </Button>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default WebcamRecorder;
