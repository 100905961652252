import { Heading, VStack, Text, Image, HStack, Box } from '@chakra-ui/react';
import React from 'react';
import { getSpacing } from '../utils';

const TitleElement = ({ element }) => {
  const { imgPathUrl, title, subTitle, titleImgPathUrl, margin } = element;

  return (
    <VStack
      gap={1}
      alignItems={'center'}
      w={'100%'}
      textAlign={'center'}
      mt={getSpacing(margin?.top) || 0}
      mb={getSpacing(margin?.bottom) || 0}
      ml={getSpacing(margin?.left) || 0}
      mr={getSpacing(margin?.right) || 0}
    >
      {(imgPathUrl || titleImgPathUrl) && (
        <Image
          src={
            imgPathUrl?.replace('{{size}}', '1x') ||
            titleImgPathUrl?.replace('{{size}}', '1x')
          }
          alt={''}
          w={'4rem'}
        />
      )}
      {title && (
        <HStack gap={2}>
          <Box
            color={'#eee'}
            fontWeight={'bold'}
            fontSize={'4xl'}
            lineHeight={'1.2'}
            dangerouslySetInnerHTML={{ __html: title.replace() }}
          />
        </HStack>
      )}

      {subTitle && (
        <Box
          color={'shadedGray.400'}
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
      )}
    </VStack>
  );
};

export default TitleElement;
