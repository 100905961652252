import React, { useCallback } from 'react';
import InputComponent from '../../components/Input';
import { VStack } from '@chakra-ui/react';
import { WORKFLOW_INPUT_TYPE } from '../../constants';

const InputElementUnderlined = ({
  element,
  value,
  handleChange,
  autoComplete = 'off'
}) => {
  const { id, disabled, label, invisible, hint, optional, inputType } = element;

  const handleLocalChange = useCallback(
    (e) => {
      if (inputType === WORKFLOW_INPUT_TYPE.TEXT_ALL_CAPS) {
        handleChange({
          target: {
            name: e.target.name,
            value: e.target.value?.toUpperCase()
          }
        });
      } else handleChange(e);
    },
    [handleChange, inputType]
  );

  //todo: handle Underlined styling
  return (
    <VStack
      w={'100%'}
      alignItems={'flex-start'}
      display={invisible ? 'none' : 'flex'}
    >
      <InputComponent
        type="text"
        value={value}
        name={id}
        handleChange={handleLocalChange}
        placeholder={hint}
        inputTitle={label || hint}
        size={{ base: 'md', sm: 'lg' }}
        required={!optional}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </VStack>
  );
};

export default InputElementUnderlined;
