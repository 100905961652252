import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LoginInitiate from './views/Login/LoginInitiate';
import SubmitOtp from './views/Login/SubmitOtp';
import Onboard from './views/Login/Onboard';
import Login from './views/Login/Login';

function UnauthenticatedApp() {
  return (
    <BrowserRouter>
      {/* {import.meta.env.PROD && <SegmentAnalytics />} */}
      <Routes>
        <Route path="login/initiate" element={<LoginInitiate />} />
        <Route path="login/submit-otp" element={<SubmitOtp />} />
        <Route path="login/onboard" element={<Onboard />} />
        <Route path="login" element={<Login />} />

        <Route path="/*" element={<Navigate to="/login/initiate" />} />
      </Routes>
    </BrowserRouter>
  );
}

export { UnauthenticatedApp };
