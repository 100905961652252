const AUTH = {
  UPDATE_LOGIN_INITIATE_DATA: 'UPDATE_LOGIN_INITIATE_DATA',
  UPDATE_SUBMIT_OTP_DATA: 'UPDATE_SUBMIT_OTP_DATA',
  GETTING_ME: 'GETTING_ME',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_FAILURE: 'GET_ME_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE'
};

export default AUTH;
