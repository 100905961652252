import { v4 as uuidv4 } from 'uuid';
import {
  INVESTMENT_KYC_STATUS,
  KYC_STATUS,
  WORKFLOWS,
  WORKFLOW_STATUS
} from '../constants';

export const formatDate = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      minute: 'numeric',
      hour: 'numeric',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDateWithoutTime = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDobDate = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const toErrorData = (error) => {
  if (!error) return null;
  return {
    status: error.response?.status || null,
    message: error.message || null,
    code: error.code || null,
    data: error.response?.data || null
  };
};

//check if a value is falsy, excluding 0
export const doesExist = (value) => value !== null && value !== undefined;

//for development
export const dummyApi = (response, error, timeout = 2000) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (response)
        return resolve({
          data: response
        });
      else return reject(error);
    }, timeout);
  });

export const initializeDeviceId = () => {
  let deviceId = localStorage.getItem('device-id');
  if (deviceId) return;

  deviceId = uuidv4();

  localStorage.setItem('device-id', deviceId);
};

const isUpperCase = (letter) =>
  letter === letter.toUpperCase() && letter !== letter.toLowerCase();
const isLowerCase = (letter) =>
  letter !== letter.toUpperCase() && letter === letter.toLowerCase();

const decamelizeString = (str) => {
  const arr = [];
  let currWord = '';

  for (let i = 0; i < str.length; i++) {
    if (str[i] === '_') {
      arr.push(currWord);
      currWord = '';
    } else if (i > 0 && isUpperCase(str[i]) && isLowerCase(str[i - 1])) {
      arr.push(currWord);
      currWord = '' + str[i];
    } else {
      currWord += str[i];
    }
  }

  if (currWord !== '') arr.push(currWord);

  let res = '';

  arr.forEach((word) => {
    if (isUpperCase(word)) res += '_' + word;
    else res += '_' + word.toLowerCase();
  });

  if (res[0] === '_') res = res.slice(1);
  return res;
};

export const decamelizeObjectKeys = (obj) => {
  const decamelizedObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let decamelizedKey = decamelizeString(key);
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        // Recursively decamelize nested objects
        decamelizedKey = decamelizeObjectKeys(value);
      }

      decamelizedObj[decamelizedKey] = value;
    }
  }

  return decamelizedObj;
};

export const mapScreenIdToNumber = (screens) =>
  Object.keys(screens)
    .map((screenNo) => ({
      screenNo: screenNo,
      screenId: screens[screenNo].screenId
    }))
    .reduce(
      (prev, screen) => ({
        ...prev,
        [screen.screenId]: screen.screenNo
      }),
      {}
    );
