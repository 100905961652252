import { VStack, StackDivider, Box, Grid, Divider } from '@chakra-ui/react';
import ExitButton from '../components/ExitButton';
import Logo from '../components/Logo';

export default function ContentLayout({ children, ...props }) {
  return (
    <VStack
      bg={'black'}
      w={'100vw'}
      h={'100vh'}
      overflowX={'hidden'}
      overflowY={'auto'}
      alignItems="start"
      divider={
        <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      }
    >
      <Box h={'10vh'} ml={'8rem'}>
        <Logo />
      </Box>
      <Grid
        gridTemplateColumns={{ base: '1fr', lg: 'repeat(3, auto) 1fr' }}
        w={'100%'}
        h={{ lg: '90vh' }}
      >
        <Box
          p={{ base: '0.5rem', lg: '1rem' }}
          pl={{ base: '1rem', lg: '1rem' }}
        >
          <ExitButton />
        </Box>

        <Divider
          orientation={'vertical'}
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
        />
        <Divider
          orientation={'horizontal'}
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
        />

        {children}
      </Grid>
    </VStack>
  );
}
