export const LOGIN_NEXT_STEP = {
  OTP_VERIFY_AND_ONBOARD: 'OTP_VERIFY_AND_ONBOARD',
  OTP_VERIFY_AND_LOGIN: 'OTP_VERIFY_AND_LOGIN',
  USER_LOGIN: 'USER_LOGIN'
};

export const WORKFLOWS = {
  // KYC_WORKFLOW: {
  //   id: 'kyc',
  //   title: 'Complete your KYC',
  //   path: 'kyc',
  //   name: 'KYC'
  // },
  INVESTMENT_KYC_WORKFLOW: {
    id: 'investmentKyc',
    title: 'Complete your verification',
    path: 'investment-kyc',
    name: 'KYC',
    STATES: {
      NEW: {
        text: 'Complete your verification',
        color: '#81EBAB',
        border: 'none'
      },
      PROCESSING: {
        text: 'Investment account verification under process',
        color: '#F8EFA8',
        border: '1px solid #F8EFA8'
      },
      COMPLETED: {
        text: 'Investment account verification',
        color: '#81EBAB',
        border: '1px solid #81EBAB'
      }
    }
  },
  INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW: {
    id: 'investmentNRIWorkflow',
    title: 'Add your bank account',
    path: 'investment-nri',
    name: 'NRI Account',
    STATES: {
      NEW: {
        text: 'Add your bank account',
        color: '#81EBAB',
        border: 'none'
      },
      PROCESSING: {
        text: 'Bank account verification under process',
        color: '#F8EFA8',
        border: '1px solid #F8EFA8'
      },
      COMPLETED: {
        text: 'Bank account verification',
        color: '#81EBAB',
        border: '1px solid #81EBAB'
      }
    }
  }
};

export const WORKFLOW_INPUT_TYPE = {
  TEXT_ALL_CAPS: 'TEXT_ALL_CAPS'
};

export const WORKFLOWS_PATH_TO_ID_MAP = {
  kyc: 'KYC_WORKFLOW',
  'investment-kyc': 'INVESTMENT_KYC_WORKFLOW',
  'investment-nri': 'INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW'
};

export const WORKFLOW_ACTION_TYPES = {
  START_WORKFLOW_ACTION: 'START_WORKFLOW_ACTION'
};

export const ELEMENT_VALIDATOR_TYPES = {
  STRING_VALIDATOR: 'StringValidator',
  REGEX_VALIDATOR: 'RegexValidator',
  RANGE_VALIDATOR: 'RangeValidator'
};

export const KYC_STATUS = {
  E_KYC_PENDING: 'E_KYC_PENDING',
  E_KYC_REJECTED: 'E_KYC_REJECTED',
  M_KYC_PENDING: 'M_KYC_PENDING',
  COMPLETED: 'COMPLETED',
  COMPLETED_NEW: 'COMPLETED_NEW',
  REJECTED: 'REJECTED'
};

export const INVESTMENT_KYC_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED'
};

export const WORKFLOW_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED'
};
