import React, { useCallback, useEffect, useState } from 'react';
import { Button, VStack, Text } from '@chakra-ui/react';
import Logo from '../../components/Logo';
import InputComponent from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';

import AuthLayout from '../../layouts/AuthLayout';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../store/actions/alertActions';
import { updateSubmitOtpData } from '../../store/actions/authActions';

const SubmitOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate('/');

  const [formData, setFormData] = useState({
    code: '',
    number: '',
    otpReferenceId: '',
    otp: ''
  });

  const loginInitiateData = useSelector(
    (state) => state.auth.loginInitiateData
  );

  const submitOtpData = useSelector((state) => state.auth.submitOtpData);

  useEffect(() => {
    if (!loginInitiateData) return navigate('/login/initiate');

    setFormData((prev) => ({
      ...prev,
      code: loginInitiateData.code,
      number: loginInitiateData.number,
      otpReferenceId: loginInitiateData.otpReferenceId
    }));
  }, [loginInitiateData]);

  useEffect(() => {
    if (submitOtpData) {
      if (loginInitiateData?.newUser) navigate('/login/onboard');
      else navigate('/login');
    }
  }, [submitOtpData]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  const isValidOTP = useCallback((pin) => {
    if (pin?.length === 4) {
      return /^\d+$/.test(pin);
    }
    return false;
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setIsLoading(true);

      if (!isValidOTP(formData.otp)) {
        dispatch(
          setAlert({
            title: 'Please provide a valid OTP',
            status: 'error'
          })
        );
        return setIsLoading(false);
      }

      api.auth
        .submitOtp({
          ...formData,
          otp: parseInt(formData.otp)
        })
        .then((res) => res.data)
        .then((data) => {
          dispatch(updateSubmitOtpData(data));
        })
        .catch((err) => {
          let errorMessage;
          console.log(err);

          if (err.data) {
            try {
              const errorData = JSON.parse(err.data);
              errorMessage = errorData.message;
            } catch (error) {
              console.log('Error parsing JSON:', error);
            }
          }

          dispatch(
            setAlert({
              title:
                errorMessage ||
                err.message ||
                'Something went wrong! Please try again later',
              status: 'error'
            })
          );
        })
        .finally(() => setIsLoading(false));
    },
    [formData, isValidOTP, dispatch]
  );

  return (
    <AuthLayout>
      <Logo justifyContent={{ base: 'center', sm: 'start' }} />
      <VStack
        as="form"
        w={{ base: '100%', sm: '500px', lg: '500px' }}
        my={8}
        mb={12}
        maxW="100%"
        gap={4}
      >
        <VStack w={'100%'} gap={1}>
          <Text
            fontSize="4xl"
            alignSelf={{ base: 'center', sm: 'flex-start' }}
            color="white"
          >
            Submit OTP
          </Text>
          <Text
            fontSize="sm"
            alignSelf={{ base: 'center', sm: 'flex-start' }}
            color="shadedGray.400"
          >
            We have sent an OTP to your number, please very the OTP
          </Text>
        </VStack>

        <VStack w={'100%'} gap={5}>
          <InputComponent
            type="text"
            value={formData.otp}
            name="otp"
            handleChange={handleChange}
            placeholder=""
            // inputTitle="Verify OTP"
            size={{ base: 'md', sm: 'lg' }}
            required
          />

          <Button
            type="submit"
            color="black"
            rounded="lg"
            colorScheme="brand"
            onClick={onSubmit}
            fontWeight={600}
            alignSelf={{ base: 'center', sm: 'start' }}
            isLoading={isLoading}
            px={5}
          >
            Submit OTP
          </Button>
        </VStack>
      </VStack>
    </AuthLayout>
  );
};

export default SubmitOtp;
