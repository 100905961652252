// Utility function to evaluate ComparableValuePredicate
const evaluateComparableValuePredicate = (predicate, formData) => {
  const elementValue = formData[predicate.elementId];

  if (predicate.type === 'EqualRulePredicate') {
    return elementValue === predicate.value;
  } else if (predicate.type === 'NotEqualRulePredicate') {
    return elementValue !== predicate.value;
  }

  return false; // Default case if the predicate type is not recognized
};

// Utility function to evaluate PredicateGroup
const evaluatePredicateGroup = (predicate, formData) => {
  if (predicate.type === 'AndRulePredicate') {
    return predicate.predicates.every((subPredicate) =>
      evaluatePredicate(subPredicate, formData)
    );
  } else if (predicate.type === 'OrRulePredicate') {
    return predicate.predicates.some((subPredicate) =>
      evaluatePredicate(subPredicate, formData)
    );
  }

  return false; // Default case if the predicate type is not recognized
};

// Utility function to evaluate predicates
export const evaluatePredicate = (predicate, formData) => {
  if (isComparablePredicate(predicate)) {
    return evaluateComparableValuePredicate(predicate, formData);
  } else if (isGroupPredicate(predicate)) {
    return evaluatePredicateGroup(predicate, formData);
  }

  return false; // Default case if the predicate type is not recognized
};

const isComparablePredicate = (predicate) =>
  predicate.type === 'EqualRulePredicate';

const isGroupPredicate = (predicate) =>
  predicate.type === 'AndRulePredicate' || predicate.type === 'OrRulePredicate';
