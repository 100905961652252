import api from '../../api';
import { WORKFLOW as types } from '../constants';

export const updateCurrentScreen = ({ workflowId, screenNo }) => ({
  type: types.UPDATE_CURRENT_WORKFLOW_SCREEN,
  payload: {
    workflowId,
    currentScreen: screenNo
  }
});

export const getWorkflow =
  ({ workflowId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_WORKFLOW,
        payload: {
          workflowId
        }
      });

      const { data } = await api.workflow.get({ workflowId });

      dispatch({
        type: types.GET_WORKFLOW_SUCCESS,
        payload: {
          workflowId,
          data
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.GET_WORKFLOW_FAILURE,
        payload: {
          workflowId,
          data: error
        }
      });
    }
  };

export const workFlowCtaSuccess = ({
  workflowId,
  updateAction,
  screenFormData,
  nextScreen,
  currentScreenNo,
  totalSteps
}) => ({
  type: types.WORKFLOW_CTA_SUCCESS,
  payload: {
    workflowId,
    data: {
      updateAction,
      screenFormData,
      nextScreen,
      currentScreenNo,
      totalSteps
    }
  }
});

export const clearWorkflow = () => ({
  type: types.CLEAR_WORKFLOW
});
