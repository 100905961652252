import { VStack } from '@chakra-ui/react';
import React from 'react';
import TitleElement from '../../factory/factoryComponents/TitleElement';

const WorkflowCompletedScreen = ({ workflowName }) => {
  const element = {
    title: `Your ${workflowName} is under review!`,
    subTitle: 'We are verifying your details, Please hang tight',
    imgPathUrl:
      'https://d17i1y54ugg6uo.cloudfront.net/Product-files/remittance-product/Verification-flow/3x/Upload-illustration.png'
  };
  return (
    <VStack w={'100%'} px={8} py={10} gap={10}>
      <TitleElement element={element}></TitleElement>
    </VStack>
  );
};

export default WorkflowCompletedScreen;
