import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const ExitButton = () => {
  const navigate = useNavigate();

  return (
    <AiOutlineClose
      cursor={'pointer'}
      color="gray"
      style={{ alignSelf: 'flex-start' }}
      size={'2rem'}
      onClick={() => {
        navigate('/');
      }}
    />
  );
};

export default ExitButton;
