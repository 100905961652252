export default function workflowCtaApi(fetch, baseUrl = '') {
  return {
    ctaRequest(endpoint, methodType, formData) {
      switch (methodType) {
        case 'PUT':
          return this.putRequest(endpoint, formData);
        case 'POST':
          return this.postRequest(endpoint, formData);
        case 'DELETE':
          return this.deleteRequest(endpoint, formData);
        default:
          return this.getRequest(endpoint);
      }
    },

    ctaRequest(endpoint, methodType, data, contentType) {
      let formData;

      if (contentType === 'multipart/form-data') {
        formData = new FormData();
        if (data.fileName) formData.append('file', data.file, data.fileName);
        else formData.append('file', data.file);

        formData.append('fileId', data.fileId);
      } else {
        formData = data;
      }

      switch (methodType) {
        case 'PUT':
          return this.putRequest(endpoint, formData, contentType);
        case 'POST':
          return this.postRequest(endpoint, formData, contentType);
        case 'DELETE':
          return this.deleteRequest(endpoint, formData, contentType);
        default:
          return this.getRequest(endpoint);
      }
    },

    getRequest(endpoint) {
      return fetch(`${baseUrl}${endpoint}`);
    },

    postRequest(endpoint, formData, contentType = 'application/json') {
      return fetch.post(`${baseUrl}${endpoint}`, formData, {
        headers: {
          'Content-Type': contentType
        }
      });
    },

    putRequest(endpoint, formData, contentType = 'application/json') {
      return fetch.put(`${baseUrl}${endpoint}`, formData, {
        headers: {
          'Content-Type': contentType
        }
      });
    },

    deleteRequest(endpoint, formData, contentType = 'application/json') {
      return fetch.delete(`${baseUrl}${endpoint}`, formData, {
        headers: {
          'Content-Type': contentType
        }
      });
    }
  };
}
