import { VStack } from '@chakra-ui/react';
import React from 'react';
import RecordVideo from '../../components/RecordVideo';
import TitleElement from './TitleElement';

const VideoRecordElement = ({ element, value, handleChange }) => {
  const { invisible } = element;

  return (
    <>
      <TitleElement element={element} />
      <VStack
        w={'100%'}
        alignItems={'flex-start'}
        display={invisible ? 'none' : 'flex'}
      >
        <RecordVideo
          element={element}
          value={value}
          handleChange={handleChange}
        />
      </VStack>
    </>
  );
};

export default VideoRecordElement;
