import { INVESTMENTS as types } from '../constants';
const initialState = {
  investmentKyc: {
    status: null,
    isLoading: false,
    isError: false,
    error: null
  }
};

const investmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_INVESTMENT_KYC_STATUS:
      return {
        ...state,
        investmentKyc: {
          ...state.investmentKyc,
          status: null,
          isLoading: true,
          isError: false,
          error: null
        }
      };

    case types.GET_INVESTMENT_KYC_STATUS_SUCCESS:
      return {
        ...state,
        investmentKyc: {
          ...state.investmentKyc,
          status: payload.status,
          isLoading: false,
          isError: false,
          error: null
        }
      };

    case types.GET_INVESTMENT_KYC_STATUS_FAILURE:
      return {
        ...state,
        investmentKyc: {
          ...state.investmentKyc,
          status: null,
          isLoading: false,
          isError: true,
          error: payload
        }
      };

    default:
      return state;
  }
};

export default investmentReducer;
