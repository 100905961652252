import React, { useCallback } from 'react';
import InputComponent from '../../components/Input';
import { VStack } from '@chakra-ui/react';
import { WORKFLOW_INPUT_TYPE } from '../../constants';
import { getSpacing } from '../utils';

const InputElementFilled = ({
  element,
  value,
  handleChange,
  autoComplete = 'off',
  inputType
}) => {
  const { id, disabled, label, invisible, hint, optional, margin } = element;

  const handleLocalChange = useCallback(
    (e) => {
      if (inputType === WORKFLOW_INPUT_TYPE.TEXT_ALL_CAPS) {
        handleChange({
          target: {
            name: e.target.name,
            value: e.target.value?.toUpperCase()
          }
        });
      } else handleChange(e);
    },
    [handleChange, inputType]
  );

  return (
    <VStack
      w={'100%'}
      alignItems={'flex-start'}
      display={invisible ? 'none' : 'flex'}
      mt={getSpacing(margin?.top) || 0}
      mb={getSpacing(margin?.bottom) || 0}
      ml={getSpacing(margin?.left) || 0}
      mr={getSpacing(margin?.right) || 0}
    >
      <InputComponent
        type="text"
        value={value}
        name={id}
        handleChange={handleLocalChange}
        placeholder={hint}
        inputTitle={label || hint}
        size={{ base: 'md', sm: 'lg' }}
        required={!optional}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </VStack>
  );
};

export default InputElementFilled;
