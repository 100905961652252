import React, { useEffect } from 'react';
import {
  Step,
  StepIcon,
  StepIndicator,
  StepStatus,
  VStack,
  Stepper,
  Progress
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/actions/alertActions';
import ScreenBuilder from '../factory';
import { updateCurrentScreen } from '../store/actions/workflowActions';
import { getMe } from '../store/actions/authActions';
import WorkflowCompletedScreen from '../views/Workflow/WorkflowCompletedScreen';

const WorkflowLayout = ({
  workflowId,
  workflowName,
  totalSteps,
  currentStep,
  screens,
  currentLocalScreen,
  initFormData
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const max = totalSteps - 1;
  const progressPercent = (currentLocalScreen / max) * 100;

  const onBackClick = () =>
    currentLocalScreen > 0 &&
    dispatch(
      updateCurrentScreen({ workflowId, screenNo: currentLocalScreen - 1 })
    );

  const onNextClick = () =>
    currentLocalScreen < currentStep &&
    dispatch(
      updateCurrentScreen({ workflowId, screenNo: currentLocalScreen + 1 })
    );

  const onWorkflowFinish = () => {
    dispatch(
      setAlert({
        title: `Your ${workflowName} is under review! Hang tight`,
        status: 'success'
      })
    );
    dispatch(getMe());
    navigate('/');
  };

  // useEffect(() => {
  //   if (
  //     totalSteps === currentStep &&
  //     screens &&
  //     Object.keys(screens).length <= totalSteps
  //   )
  //     return navigate('/');
  // }, [totalSteps, currentStep]);

  //if current steps reaches totalSteps, and no more screens to show

  if (
    (!screens || totalSteps === currentStep) &&
    Object.keys(screens).length <= totalSteps
  ) {
    return <WorkflowCompletedScreen workflowName={workflowName} />;
  }

  return (
    <VStack
      w={'100%'}
      maxW={'100vw'}
      px={{ base: 4, md: 6, lg: 8 }}
      py={{ base: 8, lg: 10 }}
    >
      <VStack position={'relative'} w={'100%'}>
        <Stepper
          size="sm"
          index={currentLocalScreen}
          gap="0"
          w={'100%'}
          maxW={'30rem'}
          zIndex={2}
          colorScheme="brand"
        >
          {Array.from({ length: totalSteps }).map((_, index) => (
            <Step key={index} gap="0">
              <StepIndicator bg="white">
                <StepStatus complete={<StepIcon />} />
              </StepIndicator>
            </Step>
          ))}
        </Stepper>
        <Progress
          value={progressPercent}
          position="absolute"
          height="3px"
          width="full"
          maxW={'30rem'}
          top="10px"
          zIndex={1}
          colorScheme="brand"
        />
      </VStack>
      <ScreenBuilder
        workflowId={workflowId}
        workflowName={workflowName}
        screen={screens[currentLocalScreen]}
        onBackClick={
          currentLocalScreen > 0 && totalSteps !== currentStep
            ? onBackClick
            : null
        }
        onNextClick={
          currentLocalScreen < currentStep && totalSteps !== currentStep
            ? onNextClick
            : null
        }
        currenScreenNo={currentLocalScreen}
        onWorkflowFinish={onWorkflowFinish}
        initFormData={initFormData[currentLocalScreen] || {}}
      />
    </VStack>
  );
};

export default WorkflowLayout;
