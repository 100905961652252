import {
  AspectRatio,
  ModalBody,
  ModalCloseButton,
  ModalHeader
} from '@chakra-ui/react';
import React from 'react';

const VideoModal = ({ title, videoUrl }) => {
  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        {title}
      </ModalHeader>

      <ModalCloseButton />
      <ModalBody px={4} py={6}>
        <AspectRatio w={'50rem'} maxW={'100%'} ratio={1}>
          <iframe title={title} src={videoUrl} allowFullScreen />
        </AspectRatio>
      </ModalBody>
    </>
  );
};

export default VideoModal;
