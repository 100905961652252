import { AUTH as types } from '../constants';

const initialState = {
  loginInitiateData: null,
  submitOtpData: null,
  user: {
    isLoading: true,
    isError: false,
    error: null,
    data: null
  }
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_LOGIN_INITIATE_DATA:
      return {
        ...state,
        loginInitiateData: payload
      };

    case types.UPDATE_SUBMIT_OTP_DATA:
      return {
        ...state,
        submitOtpData: payload
      };

    case types.GETTING_ME:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GET_ME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };

    case types.GET_ME_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          isError: true,
          error: payload,
          data: null
        }
      };

    case types.LOGOUT:
      return {
        ...state,
        loginInitiateData: null,
        submitOtpData: null,
        user: {
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    default:
      return { ...state };
  }
};

export default authReducer;
