import React from 'react';
import LogoWordmark from '../../logo/logo_wordmark.svg';
import { HStack, Text, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

const Logo = ({ justifyContent = 'start' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <HStack
      justifyContent={justifyContent}
      cursor={'pointer'}
      onClick={() => {
        if (location.pathname !== '/') navigate('/');
      }}
    >
      <Image src={LogoWordmark} boxSize={20} alt="Vance" />
      {/* <Text fontSize="xl" color="white">
        | alphadesk
      </Text> */}
    </HStack>
  );
};

export default Logo;
