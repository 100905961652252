import { Radio, RadioGroup, Stack, VStack, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';

const RadioGroupVertical = ({ element, value, handleChange }) => {
  const { label, radioButtons, disabled, invisible, id, optional } = element;

  const handleLocalChange = useCallback(
    (value) => {
      handleChange({
        target: {
          name: id,
          value: value
        }
      });
    },
    [id, handleChange]
  );

  return (
    <VStack
      w={'100%'}
      alignItems={'flex-start'}
      color={'white'}
      display={invisible ? 'none' : 'flex'}
    >
      <RadioGroup
        defaultValue="2"
        onChange={handleLocalChange}
        value={value}
        name={id}
        isDisabled={disabled}
        required={!optional}
        focusBorderColor="brand.400"
        w={'100%'}
      >
        <VStack spacing={2} w={'100%'} alignItems={'flex-start'}>
          {radioButtons.map((button) => (
            <VStack w={'100%'} bg={'altGray.400'} rounded={'lg'} px={5} py={4}>
              <Radio
                key={button.id}
                colorScheme="brand"
                value={button.id}
                disabled={button.disabled}
                w={'100%'}
              >
                {button.text}
              </Radio>
            </VStack>
          ))}
        </VStack>
      </RadioGroup>
    </VStack>
  );
};

export default RadioGroupVertical;
