import {
  HStack,
  VStack,
  Text,
  Button,
  Icon,
  Flex,
  Box
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import { BsCheckCircleFill } from 'react-icons/bs';

const EmailVerifyCard = ({ email, isEmailVerified }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    setIsLoading(true);
    api.auth
      .resendEmail(email)
      .then((res) => res.data)
      .then((data) => {
        dispatch(
          setAlert({
            title: 'We have sent you an email with the verification link!',
            status: 'success'
          })
        );
      })
      .catch((err) => {
        let errorMessage;
        console.log(err);

        if (err.data) {
          try {
            const errorData = JSON.parse(err.data);
            errorMessage = errorData.message;
          } catch (error) {
            console.log('Error parsing JSON:', error);
          }
        }

        console.log(errorMessage);

        dispatch(
          setAlert({
            title:
              errorMessage ||
              err.message ||
              'Something went wrong! Please try again later',
            status: 'error'
          })
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box position="relative" width={{ base: '100%', sm: 'auto' }}>
      {isEmailVerified && (
        <Icon
          as={BsCheckCircleFill}
          position={'absolute'}
          top={'-3px'}
          right={'-3px'}
          color={'brand.200'}
        />
      )}
      <VStack
        bg={'altGray.400'}
        px={4}
        py={3}
        gap={2}
        rounded={'lg'}
        alignItems={'flex-start'}
        maxW={'100%'}
      >
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 2, sm: 6, md: 8 }}
          justifyContent={'flex-start'}
          alignItems={'center'}
          w={'100%'}
        >
          <HStack gap={3} justifyContent={{ base: 'center', sm: 'flex-start' }}>
            <Icon color={'shadedGray.200'} as={AiOutlineMail} />
            <Text color={'shadedGray.200'}>{email}</Text>
          </HStack>
          {!isEmailVerified ? (
            <Button
              variant={'link'}
              fontWeight={500}
              colorScheme="brand"
              onClick={handleClick}
              isLoading={isLoading}
            >
              resend email
            </Button>
          ) : (
            <Text variant={'link'} fontWeight={500} color={'brand.500'}>
              email verified
            </Text>
          )}
        </Flex>
        {!isEmailVerified && (
          <HStack justifyContent={'flex-start'} w={'100%'}>
            <Text
              fontSize={'0.8rem'}
              color={'shadedGray.400'}
              textAlign={{ base: 'center', sm: 'start' }}
            >
              We have sent you an email. Please click the link in it to verify
            </Text>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default EmailVerifyCard;
