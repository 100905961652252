import axios from 'axios';
import auth from '../auth';
import authApi from './auth';

import { camelizeKeys, decamelizeKeys } from 'humps';
import { decamelizeObjectKeys, toErrorData } from '../utils';
import configApi from './config';
import workflowApi from './workflow';
import workflowCtaApi from './workflowCTA';
import investmentsApi from './investments';

const isDevelopment =
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development';

const url = !isDevelopment
  ? 'https://api.vance.tech'
  : 'https://api-uat.vance.tech'; // Replace with Env-Specific URL

/* LOCAL SERVER */
// const url = 'http://localhost:5000';

export const fetch = axios.create({
  baseURL: url,
  // timeout: 8000,
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  },
  transformResponse: [(res) => res] // change to res.data
});

export const rawFetch = (url) =>
  axios.create({
    baseURL: url,
    timeout: 4000,
    headers: {
      common: {
        'Content-Type': 'application/json'
      }
    }
  });

fetch.interceptors.request.use((config) => {
  const token = auth.getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${auth.getToken()}`;
  }
  config.headers['x-device-id'] = auth.getDeviceId();

  /* decamalize  params, and body */
  if (config.headers['Content-Type'] === 'multipart/form-data') return config;
  if (config.params) {
    config.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    config.data = decamelizeObjectKeys(config.data);
  }
  return config;
});

//returns parsed and camelized response data
fetch.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(JSON.parse(response.data)); //parse and camelize
    }

    return response;
  },
  (error) => {
    return Promise.reject(toErrorData(error));
  }
);

const api = {
  auth: authApi(fetch),
  config: configApi(fetch, '/config'),
  workflow: workflowApi(fetch, '/workflows'),
  workflowCta: workflowCtaApi(fetch),
  investments: investmentsApi(fetch, '/investments')
};

export default api;
