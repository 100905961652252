import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  VStack,
  Text,
  Select,
  HStack,
  Grid,
  FormLabel
} from '@chakra-ui/react';
import Logo from '../../components/Logo';
import InputComponent from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';
import FullPageSpinner from '../../components/FullPageSpinner';

import AuthLayout from '../../layouts/AuthLayout';
import { getAllowedCountries } from '../../store/actions/configActions';
import api from '../../api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setAlert } from '../../store/actions/alertActions';
import { BsArrowRight } from 'react-icons/bs';
import { getMe, updateSubmitOtpData } from '../../store/actions/authActions';
import auth from '../../auth';
import { LOGIN_NEXT_STEP } from '../../constants';

const Onboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate('/');

  const [formData, setFormData] = useState({
    code: '',
    number: '',
    referenceId: '',
    passcode: '',
    deviceId: auth.getDeviceId()
  });

  const submitOtpData = useSelector((state) => state.auth.submitOtpData);
  const loginInitiateData = useSelector(
    (state) => state.auth.loginInitiateData
  );

  useEffect(() => {
    if (
      !submitOtpData &&
      loginInitiateData?.nextAction !== LOGIN_NEXT_STEP.USER_LOGIN
    )
      return navigate('/login/submit-otp');

    setFormData((prev) => ({
      ...prev,
      code: submitOtpData?.code || loginInitiateData?.code,
      number: submitOtpData?.number || loginInitiateData?.number,
      referenceId: submitOtpData?.referenceId || null
    }));
  }, [submitOtpData]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  const isValidPasscode = useCallback((pin) => {
    if (pin?.length === 6) {
      return /^\d+$/.test(pin);
    }
    return false;
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setIsLoading(true);

      if (!isValidPasscode(formData.passcode)) {
        dispatch(
          setAlert({
            title: 'Please provide a valid 6 digit passcode',
            status: 'error'
          })
        );
        return setIsLoading(false);
      }

      api.auth
        .login(formData)
        .then((res) => res.data)
        .then((data) => {
          auth.setToken(data.accessToken);
          dispatch(getMe());
          navigate('/');
        })
        .catch((err) => {
          let errorMessage;
          console.log(err);

          if (err.data) {
            try {
              const errorData = JSON.parse(err.data);
              errorMessage = errorData.message;
            } catch (error) {
              console.log('Error parsing JSON:', error);
            }
          }

          dispatch(
            setAlert({
              title:
                errorMessage ||
                err.message ||
                'Something went wrong! Please try again later',
              status: 'error'
            })
          );
        })
        .finally(() => setIsLoading(false));
    },
    [formData, navigate, dispatch]
  );

  return (
    <AuthLayout>
      <Logo justifyContent={{ base: 'center', sm: 'start' }} />
      <VStack
        as="form"
        w={{ base: '100%', sm: '500px', lg: '500px' }}
        my={8}
        mb={12}
        maxW="100%"
        gap={4}
      >
        <Text
          fontSize="4xl"
          alignSelf={{ base: 'center', sm: 'flex-start' }}
          color="white"
        >
          Verify Passcode
        </Text>

        <VStack w={'100%'}>
          <InputComponent
            type="password"
            value={formData.passcode}
            name="passcode"
            handleChange={handleChange}
            placeholder="Enter your 6 digit passcode"
            inputTitle="Passcode"
            size={{ base: 'md', sm: 'lg' }}
            required
          />
        </VStack>
        <Button
          type="submit"
          color="black"
          rounded="lg"
          colorScheme="brand"
          onClick={onSubmit}
          fontWeight={600}
          alignSelf={{ base: 'center', sm: 'start' }}
          isLoading={isLoading}
          px={5}
        >
          Submit
        </Button>
      </VStack>
    </AuthLayout>
  );
};

export default Onboard;
