import React, { useCallback, useEffect, useState } from 'react';
import { Button, VStack, Text } from '@chakra-ui/react';
import Logo from '../../components/Logo';
import InputComponent from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';

import AuthLayout from '../../layouts/AuthLayout';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../store/actions/alertActions';
import { getMe } from '../../store/actions/authActions';
import auth from '../../auth';

const Onboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate('/');

  const [formData, setFormData] = useState({
    code: '',
    number: '',
    userReferenceId: '',
    name: '',
    email: '',
    passcode: '',
    passcode2: '',
    deviceId: auth.getDeviceId(),
    userType: 'INVESTMENT_WL_WEB'
  });

  const submitOtpData = useSelector((state) => state.auth.submitOtpData);

  useEffect(() => {
    if (!submitOtpData) return navigate('/login/submit-otp');

    setFormData((prev) => ({
      ...prev,
      code: submitOtpData.code,
      number: submitOtpData.number,
      userReferenceId: submitOtpData.referenceId
    }));
  }, [submitOtpData]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  const isValidPasscode = useCallback((pin) => {
    if (pin?.length === 6) {
      return /^\d+$/.test(pin);
    }
    return false;
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setIsLoading(true);

      if (!isValidPasscode(formData.passcode)) {
        dispatch(
          setAlert({
            title: 'Please provide a valid 6 digit passcode',
            status: 'error'
          })
        );
        return setIsLoading(false);
      }

      if (formData.passcode !== formData.passcode2) {
        dispatch(
          setAlert({
            title: 'Passcodes do not match',
            status: 'error'
          })
        );
        return setIsLoading(false);
      }

      const formattedFormData = { ...formData };
      delete formattedFormData.passcode2;

      api.auth
        .onboard(formattedFormData)
        .then((res) => res.data)
        .then((data) => {
          auth.setToken(data.accessToken);
          dispatch(getMe());
          navigate('/');
        })
        .catch((err) => {
          let errorMessage;
          console.log(err);

          if (err.data) {
            try {
              const errorData = JSON.parse(err.data);
              errorMessage = errorData.message;
            } catch (error) {
              console.log('Error parsing JSON:', error);
            }
          }

          dispatch(
            setAlert({
              title:
                errorMessage ||
                err.message ||
                'Something went wrong! Please try again later',
              status: 'error'
            })
          );
        })
        .finally(() => setIsLoading(false));
    },
    [formData, dispatch, isValidPasscode, navigate]
  );

  if (!submitOtpData) {
    navigate('/login/submit-otp');
  }

  return (
    <AuthLayout>
      <Logo justifyContent={{ base: 'center', sm: 'start' }} />
      <VStack
        as="form"
        w={{ base: '100%', sm: '500px', lg: '500px' }}
        my={8}
        mb={12}
        maxW="100%"
        gap={4}
      >
        <Text
          fontSize="4xl"
          alignSelf={{ base: 'center', sm: 'flex-start' }}
          color="white"
        >
          Onboarding
        </Text>

        <VStack w={'100%'}>
          <InputComponent
            type="text"
            value={formData.name}
            name="name"
            handleChange={handleChange}
            placeholder="Your name"
            inputTitle="Name"
            size={{ base: 'md', sm: 'lg' }}
            required
          />
          <InputComponent
            type="email"
            value={formData.email}
            name="email"
            handleChange={handleChange}
            placeholder="Your email"
            inputTitle="Email"
            size={{ base: 'md', sm: 'lg' }}
            required
          />
          <InputComponent
            type="password"
            value={formData.passcode}
            name="passcode"
            handleChange={handleChange}
            placeholder="Set a 6 digit passcode"
            inputTitle="Passcode"
            size={{ base: 'md', sm: 'lg' }}
            required
          />
          <InputComponent
            type="password"
            value={formData.passcode2}
            name="passcode2"
            handleChange={handleChange}
            placeholder="Set a 6 digit passcode"
            inputTitle="Confirm Passcode"
            size={{ base: 'md', sm: 'lg' }}
            required
          />
        </VStack>
        <Button
          type="submit"
          color="black"
          rounded="lg"
          colorScheme="brand"
          onClick={onSubmit}
          fontWeight={600}
          alignSelf={{ base: 'center', sm: 'start' }}
          isLoading={isLoading}
          px={5}
        >
          Submit
        </Button>
      </VStack>
    </AuthLayout>
  );
};

export default Onboard;
