import { AuthenticatedApp } from './AuthenticatedApp';
import { UnauthenticatedApp } from './UnauthenticatedApp';
import FullPageSpinner from './components/FullPageSpinner';
import React, { useEffect } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { clearAlert } from './store/actions/alertActions';
import { initializeDeviceId } from './utils';
import { getMe } from './store/actions/authActions';

function App() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { alert } = useSelector((state) => state);
  const { isLoading, data: user } = useSelector((state) => state.auth.user);

  useEffect(() => {
    initializeDeviceId();
    dispatch(getMe());
  }, []);

  useEffect(() => {
    if (!alert.isActive) return;

    toast(alert.data);

    setTimeout(() => {
      dispatch(clearAlert());
    }, alert.data?.duration);
  }, [alert]);

  if (isLoading) return <FullPageSpinner />;
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export { App };
