export const ELEMENT_TYPES = {
  TITLE_ELEMENT: {
    type: 'TitleElement',
    isInput: false
  },
  TEXT_ELEMENT: {
    type: 'TextElement',
    isInput: false
  },
  BULLET_ELEMENT: {
    type: 'BulletElement',
    isInput: false
  },
  DROPDOWN: {
    type: 'DropDown',
    isInput: true
  },
  FIELD_UPLOAD_ELEMENT: {
    type: 'FieldUploadElement',
    isInput: true
  },
  INPUT_ELEMENT_FILLED: {
    type: 'InputElement.Filled',
    isInput: true
  },
  DATE_PICKER: {
    type: 'DatePicker',
    isInput: true
  },
  RADIO_GROUP_LABEL: {
    type: 'RadioGroup.Label',
    isInput: true
  },
  RADIO_GROUP_VERTICAL: {
    type: 'RadioGroup.Vertical',
    isInput: true
  },
  ADDRESS_WIDGET: {
    type: 'AddressWidget',
    isInput: true
  },
  INPUT_ELEMENT_FILLED_AUTOCOMPLETE: {
    type: 'InputElement.Filled.AutoComplete',
    isInput: true
  },
  INPUT_ELEMENT_UNDERLINED: {
    type: 'InputElement.Underlined',
    isInput: true
  },
  VIDEO_RECORD_ELEMENT: {
    type: 'VideoRecordElement',
    isInput: true
  },
  LOTTIE_FILE_ELEMENT: {
    type: 'LottieFileElement',
    isInput: false
  },
  CTA_ELEMENT: {
    type: 'CTAElement',
    isInput: false
  }
};

export const IS_ELEMENT_INPUT = {
  TitleElement: false,
  TextElement: false,
  BulletElement: false,
  DropDown: true,
  FieldUploadElement: true,
  'InputElement.Filled': true,
  DatePicker: true,
  'RadioGroup.Label': true,
  'RadioGroup.Vertical': true,
  AddressWidget: true,
  'InputElement.Filled.AutoComplete': true,
  'InputElement.Underlined': true,
  VideoRecordElement: true,
  CTA_ELEMENT: false,
  LottieFileElement: false
};

export const getInitialFormData = (screen, initFormData) => {
  if (screen?.screenType === 'VideoUploadScreen') {
    const videoBlock = screen?.contents?.videoBlock;
    return {
      [videoBlock?.id]:
        initFormData[videoBlock?.id] || videoBlock?.value || null
    };
  }
  const formDataArr = [];

  const fillFlattenedInputs = (element) => {
    if (!element.children?.length) {
      if (IS_ELEMENT_INPUT[element.elementType])
        formDataArr.push({
          id: element.id,
          value: element.value
        });
      return;
    }

    element.children.forEach((childElement) =>
      fillFlattenedInputs(childElement)
    );
  };

  fillFlattenedInputs(screen?.contents);

  return formDataArr.reduce(
    (prev, element) => ({
      ...prev,
      [element.id]: initFormData[element.id] || element.value || null
    }),
    {}
  );
};

export const getValidationData = (screen) => {
  if (screen?.screenType === 'VideoUploadScreen') {
    const videoBlock = screen?.contents?.videoBlock;
    return {
      [videoBlock?.id]: {
        isRequired: !videoBlock.optional,
        validators: videoBlock.validators
      }
    };
  }

  const validatorsArr = [];

  const fillFlattenedInputs = (element) => {
    if (!element.children?.length) {
      if (IS_ELEMENT_INPUT[element.elementType])
        validatorsArr.push({
          id: element.id,
          data: {
            isRequired: !element.optional,
            validators: element.validators
          }
        });
      return;
    }

    element.children.forEach((childElement) =>
      fillFlattenedInputs(childElement)
    );
  };

  fillFlattenedInputs(screen?.contents);

  return validatorsArr.reduce(
    (prev, element) => ({
      ...prev,
      [element.id]: element.data || null
    }),
    {}
  );
};

export const getFormConstraints = (screen) => {
  //MULTILEVEL FLATTENING
  const constraintsArr = [];

  const fillFlattenedInputs = (element) => {
    if (!element.children?.length) {
      if (IS_ELEMENT_INPUT[element.elementType])
        constraintsArr.push({
          id: element.id,
          data: {}
        });
      return;
    }

    element.children.forEach((childElement) =>
      fillFlattenedInputs(childElement)
    );
  };

  fillFlattenedInputs(screen?.contents);

  return constraintsArr.reduce(
    (prev, element) => ({
      ...prev,
      [element.id]: element.data
    }),
    {}
  );
};

export const getSpacing = (length) => {
  if (!length) return 0;

  if (length <= 8) return 2;
  if (length <= 12) return 4;
  if (length <= 16) return 6;
  return 8;
};

export const toRGB = (color) => {
  if (!color || typeof color === 'string') return color;

  let alpha = (color >> 24) & 0xff;
  let red = (color >> 16) & 0xff;
  let green = (color >> 8) & 0xff;
  let blue = color & 0xff;

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const hexAToRGBA = (hexA) => {
  if (!hexA || !typeof hexA === 'string') return hexA;

  hexA = hexA.replace('#', '');

  // Parse the hexadecimal values for red, green, blue, and alpha channels
  const alpha = parseInt(hexA.substring(0, 2), 16) / 255;
  const red = parseInt(hexA.substring(2, 4), 16);
  const green = parseInt(hexA.substring(4, 6), 16);
  const blue = parseInt(hexA.substring(6, 8), 16);

  // Construct the RGBA color string
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;

  return rgbaColor;
};
