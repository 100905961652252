import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

import React from 'react';

const ModalLayout = ({
  isOpen,
  onClose,
  onOpen,
  children,
  onButtonClick,
  ButtonText
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      useInert={false}
      size={'xl'}
    >
      <ModalOverlay backdropFilter={'auto'} backdropBlur="1.5px" />
      <ModalContent bg={'altGray.400'} color="white" rounded="xl" pt={'2rem'}>
        {children}
      </ModalContent>
    </Modal>
  );
};

export default ModalLayout;
