import { Heading, VStack, Text, Image, HStack, Box } from '@chakra-ui/react';
import { getSpacing, toRGB } from '../utils';

const TextElement = ({ element }) => {
  const { value, invisible, textColor, textAlignment, typography, margin } =
    element;

  //title element
  if (typography === 'DISPLAY_MD_BOLD')
    return (
      <VStack
        alignItems={'center'}
        w={'100%'}
        display={invisible ? 'none' : 'flex'}
      >
        <Box
          textAlign={textAlignment?.toLowerCase() || 'center'}
          color={'#eee'}
          mt={getSpacing(margin?.top) || 0}
          mb={getSpacing(margin?.bottom) || 0}
          ml={getSpacing(margin?.left) || 0}
          mr={getSpacing(margin?.right) || 0}
          fontWeight={'bold'}
          fontSize={'4xl'}
          lineHeight={'1.2'}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </VStack>
    );

  return (
    <VStack
      alignItems={'center'}
      w={'100%'}
      display={invisible ? 'none' : 'flex'}
    >
      <Box
        color={toRGB(textColor) || 'shadedGray.400'}
        textAlign={textAlignment?.toLowerCase() || 'center'}
        mt={getSpacing(margin?.top) || 0}
        mb={getSpacing(margin?.bottom) || 0}
        ml={getSpacing(margin?.left) || 0}
        mr={getSpacing(margin?.right) || 0}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </VStack>
  );
};

export default TextElement;
