import {
  VStack,
  Link,
  Text,
  HStack,
  Box,
  Tooltip,
  Flex,
  Button
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsArrowRight, BsCheck2Circle, BsClock } from 'react-icons/bs';
import { WORKFLOW_STATUS } from '../../constants';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';

const WorkflowCard = ({ workflow, isEmailVerified, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    if (!isEmailVerified)
      return dispatch(
        setAlert({
          title: 'Please verify your email to proceed',
          status: 'warning'
        })
      );
    else navigate('/workflows/' + workflow.path);
  };

  return status === WORKFLOW_STATUS.NEW ? (
    <Button
      onClick={onClick}
      bg={'brand.500'}
      rounded={'lg'}
      py={3}
      px={{ base: 2, sm: 4 }}
      width={{ base: '100%', sm: '16rem' }}
      cursor={'pointer'}
      fontWeight={600}
      _hover={{
        textDecoration: 'underline'
      }}
    >
      <HStack w={'100%'}>
        <Text fontSize={{ base: '0.9rem', sm: '1rem' }} maxW={'100%'}>
          {workflow.STATES[status]?.text}
        </Text>
        <BsArrowRight display={'inline'} size={'1.2rem'} />
      </HStack>
    </Button>
  ) : (
    <VStack
      border={workflow.STATES[status].border}
      rounded={'lg'}
      py={3}
      px={{ base: 2, sm: 4 }}
      alignItems={'flex-start'}
      maxW={'100%'}
      fontWeight={600}
      color={workflow.STATES[status].color}
    >
      <HStack
        w={'100%'}
        fontSize={'14px'}
        textAlign={{ base: 'center', sm: 'start' }}
      >
        {status === WORKFLOW_STATUS.COMPLETED ? (
          <BsCheck2Circle display={'inline'} size={'1.2rem'} />
        ) : (
          <BsClock display={'inline'} size={'1.2rem'} />
        )}
        <Text fontSize={{ base: '0.9rem', sm: '1rem' }} maxW={'100%'}>
          {workflow.STATES[status]?.text}
        </Text>
      </HStack>
    </VStack>
  );
};

export default WorkflowCard;
