import React, { useEffect } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearWorkflow,
  getWorkflow
} from '../../store/actions/workflowActions';
import FullPageScreen from '../../components/FullPageSpinner';
import WorkflowLayout from '../../layouts/WorkflowLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { WORKFLOWS, WORKFLOWS_PATH_TO_ID_MAP } from '../../constants';

const Workflow = () => {
  const { workflowPath } = useParams();
  const workflowId = WORKFLOWS_PATH_TO_ID_MAP[workflowPath];
  const workflow = WORKFLOWS[workflowId];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: workflowData,
    formData: initFormData,
    isLoading,
    currentLocalScreen
  } = useSelector((state) => state.workflow);

  const user = useSelector((state) => state.auth.user.data);
  const isEmailVerified = user?.emailVerified;

  useEffect(() => {
    triggerGetWorkflow({ workflowId });

    return () => dispatch(clearWorkflow());
  }, [workflowId]);

  const triggerGetWorkflow = () => {
    dispatch(getWorkflow({ workflowId }));
  };

  useEffect(() => {
    if (!isEmailVerified) navigate('/');
  }, [isEmailVerified]);

  if (isLoading) return <FullPageScreen />;

  return (
    <ContentLayout>
      {workflowData && (
        <WorkflowLayout
          workflowName={workflow.name}
          workflowTitle={workflow.title}
          currentStep={workflowData.currentStep}
          totalSteps={workflowData.totalSteps}
          completedStep={workflowData.completedStep}
          screens={workflowData.screens}
          currentLocalScreen={currentLocalScreen}
          initFormData={initFormData}
        ></WorkflowLayout>
      )}
    </ContentLayout>
  );
};

export default Workflow;
