import { combineReducers } from 'redux';
import alertReducer from './alertReducer';
import reqDataReducer from './reqDataReducer';
import configReducer from './configReducer';
import authReducer from './authReducer';
import workflowReducer from './workflowReducer';
import investmentReducer from './investmentReducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  reqData: reqDataReducer,
  config: configReducer,
  auth: authReducer,
  workflow: workflowReducer,
  investment: investmentReducer
});

export default rootReducer;
