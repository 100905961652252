import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  VStack,
  Text,
  Select,
  HStack,
  Grid,
  FormLabel
} from '@chakra-ui/react';
import Logo from '../../components/Logo';
import InputComponent from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';
import FullPageSpinner from '../../components/FullPageSpinner';

import AuthLayout from '../../layouts/AuthLayout';
import { getAllowedCountries } from '../../store/actions/configActions';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../store/actions/alertActions';
import { updateLoginIniateData } from '../../store/actions/authActions';
import auth from '../../auth';
import { LOGIN_NEXT_STEP } from '../../constants';

const LoginInitiate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate('/');
  const loginInitiateData = useSelector(
    (state) => state.auth.loginInitiateData
  );

  const [formData, setFormData] = useState({
    code: '',
    number: '',
    deviceId: auth.getDeviceId()
  });

  const { data: allowedCountries, isAllowedCountriesLoading } = useSelector(
    (state) => state.config
  );

  const [isLoading, setIsLoading] = useState(false);

  const onSendOtp = useCallback(
    (e) => {
      setIsLoading(true);
      e.preventDefault();

      api.auth
        .loginInitiate(formData)
        .then((res) => res.data)
        .then((data) => {
          dispatch(updateLoginIniateData(data));
        })
        .catch((err) => {
          let errorMessage;
          console.log(err);

          if (err.data) {
            try {
              const errorData = JSON.parse(err.data);
              errorMessage = errorData.message;
            } catch (error) {
              console.log('Error parsing JSON:', error);
            }
          }

          dispatch(
            setAlert({
              title:
                errorMessage ||
                err.message ||
                'Something went wrong! Please try again later',
              status: 'error'
            })
          );
        })
        .finally(() => setIsLoading(false));
    },
    [formData, dispatch]
  );

  useEffect(() => {
    triggerGetAllowedCountries();
  }, []);

  useEffect(() => {
    if (loginInitiateData) {
      switch (loginInitiateData.nextAction) {
        case LOGIN_NEXT_STEP.USER_LOGIN:
          return navigate('/login');
        default:
          navigate('/login/submit-otp');
      }
    }
  }, [loginInitiateData]);

  const triggerGetAllowedCountries = () => {
    dispatch(getAllowedCountries());
  };

  useEffect(() => {
    if (allowedCountries?.length && formData.code === '') {
      setFormData((prev) => ({
        ...prev,
        code: allowedCountries[0].phoneCode
      }));
    }
  }, [allowedCountries]);

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  if (isAllowedCountriesLoading) return <FullPageSpinner />;

  return (
    <AuthLayout>
      <Logo justifyContent={{ base: 'center', sm: 'start' }} />
      <VStack
        as="form"
        w={{ base: '100%', sm: '500px', lg: '500px' }}
        my={8}
        mb={12}
        maxW="100%"
      >
        <Text
          fontSize="4xl"
          alignSelf={{ base: 'center', sm: 'flex-start' }}
          color="white"
        >
          Sign in
        </Text>
        <VStack
          spacing={4}
          w="100%"
          py="6"
          boxShadow="base"
          rounded="md"
          maxW="100%"
          gap={3}
        >
          <VStack width={'100%'}>
            <FormLabel
              lineHeight={'0'}
              color={'white'}
              alignSelf={'flex-start'}
            >
              Phone number
            </FormLabel>
            <Grid
              gridTemplateColumns={'auto 1fr'}
              gap={2}
              justifyContent={'space-between'}
              // alignItems={'center'}
              w={'100%'}
            >
              <Select
                size={{ base: 'md', sm: 'lg' }}
                width={'5rem'}
                height={'48px'}
                // rounded={'lg'}
                name={'code'}
                value={formData.code}
                color={'white'}
                onChange={handleChange}
                style={{
                  border: 0,
                  background: '#414141',
                  borderRadius: '12px',
                  gap: '12px',
                  margin: '0'
                }}
                _focusVisible={{
                  boxShadow: 'brand.200',
                  borderColor: 'brand.200'
                }}
                required
              >
                {allowedCountries?.map((country) => (
                  <option key={country.code} value={country.phoneCode}>
                    {country.phoneCode}
                  </option>
                ))}
              </Select>
              <InputComponent
                type="text"
                value={formData.number}
                name="number"
                handleChange={handleChange}
                placeholder="Phone number"
                size={{ base: 'md', sm: 'lg' }}
                autoComplete={'off'}
                spellcheck={false}
                autoCorrect={'off'}
                required
              />
            </Grid>
          </VStack>
        </VStack>

        <Button
          type="submit"
          color="black"
          rounded="lg"
          colorScheme="brand"
          onClick={onSendOtp}
          fontWeight={600}
          alignSelf={{ base: 'center', sm: 'start' }}
          isLoading={isLoading}
          px={5}
        >
          Login
        </Button>
      </VStack>
    </AuthLayout>
  );
};

export default LoginInitiate;
