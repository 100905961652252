import { Input, InputGroup, Text, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { formatDateDDMMYYY } from '../../utils';

const DatePicker = ({ element, value, handleChange }) => {
  const {
    dateConstraint,
    dateFormat,
    disabled,
    id,
    invisible,
    label,
    optional
  } = element;

  /* todo: revamp fate formatting */
  const formatToDisplayDate = useCallback((dateString) => {
    if (!dateString) return '';
    let dateObj = new Date(dateString);

    try {
      var dateOptions = {
        timeZone: 'UTC',
        minute: 'numeric',
        hour: 'numeric',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      };
      dateObj = dateObj.toLocaleString('en-US', dateOptions);
    } catch (error) {
      return '';
    }
    dateObj = new Date(dateString);
    let year = dateObj.getFullYear();
    year = '0'.repeat(4 - year.toString().length) + year;
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }, []);

  const formatFromDisplayDate = useCallback((dateString) => {
    const dateTime = dateString + 'T00:00';
    return new Date(dateTime + 'Z').toISOString();
  }, []);

  return (
    <VStack
      alignItems={'flex-start'}
      w={'100%'}
      display={invisible ? 'none' : 'flex'}
    >
      <Text m="0" alignSelf="flex-start" color="white">
        {label}
      </Text>
      <Input
        type={'date'}
        name={id}
        value={formatToDisplayDate(value)}
        onChange={(e) => {
          handleChange({
            target: {
              name: e.target.name,
              value: formatFromDisplayDate(e.target.value)
            }
          });
        }}
        fontSize={'0.75rem'}
        fontWeight={'600'}
        rounded="lg"
        border={0}
        placeholder={''}
        required={!optional}
        width={'100%'}
        maxW={'30rem'}
        alignSelf={'flex-start'}
        color={'white'}
        focusBorderColor="brand.400"
        disabled={disabled}
        style={{
          border: 0,
          background: '#414141',
          borderRadius: '12px',
          gap: '12px',
          margin: '0'
        }}
        min={formatToDisplayDate(dateConstraint.start)}
        max={formatToDisplayDate(dateConstraint.end)}
      ></Input>
    </VStack>
  );
};

export default DatePicker;
