import { Box, Button, Text, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { RxVideo } from 'react-icons/rx';
import { BsFillRecordCircleFill } from 'react-icons/bs';
import WebCam from '../../components/Webcam';
import ModalLayout from '../../components/Modal/ModalLayout';
import VideoModal from '../../components/Modal/VideoModal';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import api from '../../api';

const RecordVideo = ({ element, value, handleChange }) => {
  const { invisible, id, sampleVideoUrl, sampleCaption, action, fileId } =
    element;
  const dispatch = useDispatch();
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [isWebCamOpen, setIsWebCamOpen] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure();

  const streamRef = useRef(null);

  const requestPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });

      streamRef.current = stream;
      setPermissionGranted(true);
      setIsPermissionDenied(false);
    } catch (error) {
      setIsPermissionDenied(true);
      setPermissionGranted(false);
      dispatch(
        setAlert({
          title:
            'Please allow camera and video access for completing this step!',
          status: 'warning'
        })
      );
      console.error('Error accessing webcam and audio:', error);
    }
  };

  useEffect(() => {
    return () => {
      if (streamRef?.current) {
        // Stop all tracks in the stream
        streamRef?.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const openWebCam = async () => {
    await requestPermission();

    setIsWebCamOpen(true);
  };

  const handleUpload = (recordedData) => {
    if (recordedData) {
      uploadVideo(recordedData);
    }
  };

  const uploadVideo = (video) => {
    const apiAction = action?.apiAction;

    if (!apiAction) return;

    setIsVideoUploading(true);

    api.workflowCta
      .ctaRequest(
        apiAction.endpoint,
        apiAction.methodType,
        {
          file: video,
          fileId: fileId,
          fileName: 'video.mp4'
        },
        'multipart/form-data'
      )
      .then((res) => res.data)
      .then((data) => {
        handleChange({
          target: {
            name: id,
            value: data.urlPath
          }
        });
        dispatch(
          setAlert({
            title: 'Uploaded file successfully!',
            status: 'success'
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setAlert({
            title: err.message || 'Failed to upload file!',
            status: 'error'
          })
        );
      })
      .finally(() => {
        setIsVideoUploading(false);
      });
  };

  return (
    <Box position={'relative'} w={'100%'}>
      <ModalLayout isOpen={isModalOpen} onClose={onModalClose}>
        <VideoModal title={'Sample Video'} videoUrl={sampleVideoUrl} />
      </ModalLayout>
      <VStack
        alignItems={'flex-start'}
        w={'100%'}
        display={invisible ? 'none' : 'flex'}
      >
        {!isWebCamOpen && (
          <VStack w={'100%'} gap={3}>
            <Button
              onClick={onModalOpen}
              variant={'outline'}
              color={'white'}
              _hover={{
                backgroundColor: 'white',
                color: 'black'
              }}
              // color="brand.400"
              mr={3}
              fontSize={'0.8rem'}
              alignSelf={'center'}
              rounded={'full'}
              rightIcon={<RxVideo size={'1.2rem'} />}
              width={'10rem'}
              maxW={'100%'}
            >
              Sample Video
            </Button>
            <Button
              onClick={openWebCam}
              colorScheme="brand"
              color={'black'}
              // color="brand.400"
              mr={3}
              fontSize={'0.8rem'}
              alignSelf={'center'}
              rounded={'full'}
              rightIcon={
                <BsFillRecordCircleFill
                  style={{
                    marginLeft: '40px'
                  }}
                  size={'1.2rem'}
                />
              }
              width={'10rem'}
              maxW={'100%'}
            >
              Record
            </Button>
          </VStack>
        )}

        {isWebCamOpen && !isPermissionDenied && (
          <WebCam
            permissionGranted={permissionGranted}
            requestPermission={requestPermission}
            videoMessage={sampleCaption}
            stream
            handleUpload={handleUpload}
            isUploaded={false}
            isUploading={isVideoUploading}
          />
        )}

        {isPermissionDenied && (
          <Text textAlign={'center'} size={'0.8rem'} color={'shadedGray.300'}>
            Please allow camera and microphone access to complete this step
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default RecordVideo;
