import { mapScreenIdToNumber } from '../../utils';

export const updateKycWorkflow = ({ screens, updateAction }) => {
  const screenIdMap = mapScreenIdToNumber(screens);

  const updatedScreens = { ...screens };

  if (!updateAction?.updateElements?.length) return updatedScreens;

  updateAction.updateElements.forEach((updateInstruction) => {
    const { elements, values } = updateInstruction;

    elements.forEach((element) => {
      const screenId = screenIdMap[element.screenId];
      const updatedContents = findAndUpdateElement({
        elementId: element.elementId,
        updateValues: values,
        element: updatedScreens[screenId].contents
      });

      updatedScreens[screenId] = {
        ...updatedScreens[screenId],
        contents: updatedContents
      };
    });
  });

  return updatedScreens;
};

const findAndUpdateElement = ({
  elementId,
  element, // Parent element in which to find the update element
  updateValues
}) => {
  if (elementId === element.id) {
    const updatedElement = {
      ...element,
      ...updateValues
    };
    return updatedElement;
  }

  if (!element.children) return element;

  const updatedChildren = element.children.map((child) => {
    return findAndUpdateElement({
      elementId,
      element: child,
      updateValues
    });
  });

  const updatedElement = {
    ...element,
    children: updatedChildren
  };

  return updatedElement;
};
