export default function investmentsApi(fetch, baseUrl = '') {
  return {
    getInvestmentKycStatus() {
      return fetch.get(`${baseUrl}/investors/kyc`);
    },

    autoLogin() {
      return fetch.get(`${baseUrl}/investors/auto-login`);
    }
  };
}
