import { Button, VStack } from '@chakra-ui/react';
import React from 'react';

const CTAElement = ({ element, onClick, isLoading }) => {
  const { action, disabled, invisible, title, isPrimary } = element;

  if (action?.actionType === 'DEEPLINK_ACTION') return null;

  return (
    <VStack w={'100%'} display={invisible ? 'none' : 'flex'}>
      <Button
        width={'100%'}
        onClick={() => onClick({ action })}
        disabled={disabled}
        visibility={!invisible}
        isLoading={isLoading}
        colorScheme="brand"
        color={'altGray.400'}
        fontSize={'0.8rem'}
        alignSelf={'center'}
        type={'submit'}
        rounded={'xl'}
        textDecor={'none'}
      >
        {title}
      </Button>
    </VStack>
  );
};

export default CTAElement;
