import React from 'react';
import { ELEMENT_TYPES } from './utils';
import InputElementFilled from './factoryComponents/InputElementFilled';
import TitleElement from './factoryComponents/TitleElement';
import DatePicker from './factoryComponents/DatePicker';
import RadioGroupLabel from './factoryComponents/RadioGroupLabel';
import Dropdown from './factoryComponents/Dropdown';
import AddressWidget from './factoryComponents/AddressWidget';
import FieldUploadElement from './factoryComponents/FieldUploadElement';
import InputElementUnderlined from './factoryComponents/InputElementUnderlined';
import TextElement from './factoryComponents/TextElement';
import BulletElement from './factoryComponents/BulletElement';
import RadioGroupVertical from './factoryComponents/RadioGroupVertical';
import VideoRecordElement from './factoryComponents/VideoRecordElement';
import LottieFileElement from './factoryComponents/LottieFileElement';

const ElementBuilder = ({
  element,
  handleChange,
  value,
  formData,
  setFormData,
  fieldConstraints,
  updateFieldConstraints
}) => {
  switch (element.elementType) {
    case ELEMENT_TYPES.INPUT_ELEMENT_FILLED.type:
      return (
        <InputElementFilled
          element={element}
          value={value}
          handleChange={handleChange}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.INPUT_ELEMENT_FILLED_AUTOCOMPLETE.type:
      return (
        <InputElementFilled
          element={element}
          value={value}
          handleChange={handleChange}
          autoComplete="on"
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.INPUT_ELEMENT_UNDERLINED.type:
      return (
        <InputElementUnderlined
          element={element}
          value={value}
          handleChange={handleChange}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.TITLE_ELEMENT.type:
      return <TitleElement element={element} />;

    case ELEMENT_TYPES.TEXT_ELEMENT.type:
      return <TextElement element={element} />;

    case ELEMENT_TYPES.BULLET_ELEMENT.type:
      return <BulletElement element={element} />;

    case ELEMENT_TYPES.DATE_PICKER.type:
      return (
        <DatePicker
          element={element}
          value={value}
          handleChange={handleChange}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.RADIO_GROUP_LABEL.type:
      return (
        <RadioGroupLabel
          element={element}
          value={value}
          handleChange={handleChange}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );
    case ELEMENT_TYPES.RADIO_GROUP_VERTICAL.type:
      return (
        <RadioGroupVertical
          element={element}
          value={value}
          handleChange={handleChange}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.DROPDOWN.type:
      return (
        <Dropdown
          element={element}
          value={value}
          handleChange={handleChange}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.ADDRESS_WIDGET.type:
      return (
        <AddressWidget
          element={element}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.FIELD_UPLOAD_ELEMENT.type:
      return (
        <FieldUploadElement
          element={element}
          value={value}
          handleChange={handleChange}
          formData={formData}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.VIDEO_RECORD_ELEMENT.type:
      return (
        <VideoRecordElement
          element={element}
          value={value}
          handleChange={handleChange}
          formData={formData}
          fieldConstraints={fieldConstraints}
          updateFieldConstraints={updateFieldConstraints}
        />
      );

    case ELEMENT_TYPES.LOTTIE_FILE_ELEMENT.type:
      return <LottieFileElement element={element} />;

    default:
      console.log('missing element: ', element.elementType);
      return null;
  }
};

export default ElementBuilder;
