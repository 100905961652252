import { Radio, RadioGroup, Stack, VStack, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';

const RadioGroupLabel = ({ element, value, handleChange }) => {
  const { label, radioButtons, disabled, invisible, id, optional, validators } =
    element;

  const handleLocalChange = useCallback(
    (value) => {
      handleChange({
        target: {
          name: id,
          value: value
        }
      });
    },
    [id, handleChange]
  );

  return (
    <VStack
      w={'100%'}
      alignItems={'flex-start'}
      color={'white'}
      display={invisible ? 'none' : 'flex'}
    >
      <Text m="0" alignSelf="flex-start" color="white">
        {label}
      </Text>
      <RadioGroup
        defaultValue="2"
        onChange={handleLocalChange}
        value={value}
        name={id}
        isDisabled={disabled}
        required={!optional}
        focusBorderColor="brand.400"
      >
        <Stack spacing={5} direction="row">
          {radioButtons.map((button) => (
            <Radio
              key={button.id}
              colorScheme="brand"
              value={button.id}
              disabled={button.disabled}
            >
              {button.text}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </VStack>
  );
};

export default RadioGroupLabel;
