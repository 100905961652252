import { VStack } from '@chakra-ui/react';
import React from 'react';
import ElementBuilder from '../ElementBuilder';

const AddressWidget = ({ element, formData, handleChange }) => {
  return (
    <VStack w={'100%'} justifyContent={'flex-start'}>
      {element.children.map((childElement) => (
        <ElementBuilder
          key={childElement.id}
          element={childElement}
          value={formData[childElement.id]}
          handleChange={handleChange}
        />
      ))}
    </VStack>
  );
};

export default AddressWidget;
