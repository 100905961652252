const WORKFLOW = {
  GETTING_KYC_WORKFLOW: 'GETTING_KYC_WORKFLOW',
  GET_KYC_WORKFLOW_SUCCESS: 'GETTING_KYC_WORKFLOW_SUCCESS',
  GET_KYC_WORKFLOW_FAILURE: 'GETTING_KYC_WORKFLOW_FAILURE',
  CLEAR_KYC_WORKFLOW: 'CLEAR_KYC_WORKFLOW',
  KYC_WORKFLOW_CTA_SUCCESS: 'KYC_WORKFLOW_CTA_SUCCESS',
  UPDATE_CURRENT_SCREEN: 'UPDATE_CURRENT_SCREEN',

  GETTING_WORKFLOW: 'GETTING_WORKFLOW',
  GET_WORKFLOW_SUCCESS: 'GETTING_WORKFLOW_SUCCESS',
  GET_WORKFLOW_FAILURE: 'GETTING_WORKFLOW_FAILURE',
  CLEAR_WORKFLOW: 'CLEAR_WORKFLOW',
  WORKFLOW_CTA_SUCCESS: 'WORKFLOW_CTA_SUCCESS',
  UPDATE_CURRENT_WORKFLOW_SCREEN: 'UPDATE_CURRENT_WORKFLOW_SCREEN'
};

export default WORKFLOW;
